import React from "react";
import { firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { serviciosProveedores } from "../../../providers/serviciosProveedores";
import firebase from "firebase";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FiUploadCloud } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { TiMinus } from "react-icons/ti";

class Comunidad extends React.Component {
  constructor() {
    super()
    this.state = {
      finca: undefined,
      proveedores: [],
      existeHojaVisita: false,
      anoFechaVisita: undefined,
      anoFechaVisitaCt: 0,
      hojasDeVisitaHTML:[]
    }
  }
  componentDidMount() {
    firestore.collection("fincas").doc(this.props.params.nif).get().then(snapshot => {
      let finca = snapshot.data();
      let proveedores = [];
      let promises = [];
      promises.push(firestore.collection("aaff").doc(finca.nifAAFF).get().then(a=> {finca.aaff = {telefono:a.data().telefono, email: a.data().email, nombre: a.data().contacto}}))
      finca.proveedores.forEach(proveedor => {
        promises.push(firestore.collection("proveedores").doc(proveedor.nif).get().then(p => proveedores.push({ ...p.data(), servicio: proveedor.servicio })))
      });
      Promise.all(promises).then(async () => {
        this.setState({ finca: finca, proveedores: proveedores })
        const html = await this.loopHv(finca.documentos.hojaVisita?.ano === undefined ? 2022 : finca.documentos.hojaVisita?.ano, finca)
        this.setState({ hojasDeVisitaHTML: html });
      })
    }).catch(error => {
      window.location.replace("/")
    })
    firestore.collection("hojasVisita").doc(this.props.params.nif).get().then( hv => hv.exists ? this.setState({existeHojaVisita:true, anoFechaVisita: new Date(hv.data().fechaVisita?.seconds*1000).getFullYear()}) : this.setState({existeHojaVisita:false}))
    firestore.collection("hojasVisita").doc(this.props.params.nif).get().then( hv => {
      if(hv.exists && hv.data().centrosTrabajo !== undefined){
        console.log(hv.data())
        if(hv.data().centrosTrabajo.length === 0){
          this.setState({anoFechaVisitaCt: new Date().getFullYear()})
        }else{
          hv.data().centrosTrabajo.forEach(ct => {
            console.log(ct.nif)
            this.setState({anoFechaVisitaCt: new Date(ct.fechaVisita?.seconds*1000).getFullYear()})
          })
        }
      }else{
        this.setState({anoFechaVisitaCt: new Date().getFullYear()})
      } 
    })  
  }


  subirLOPD = (event) => {
    storage.ref(`${this.state.finca.nif}/${event.target.files[0]["name"]}`).put(event.target.files[0]).then(snapshot => {
      this.setState(prevState => ({
        finca: {
          ...prevState.finca,
          estado: prevState.finca.documentos.lopd?.validado ? prevState.finca.estado - 5 : prevState.finca.estado,
          documentos: {
            ...prevState.finca.documentos,
            lopd: {
              ...prevState.finca.documentos.lopd,
              ruta: snapshot.ref.fullPath,
              pendiente: true,
              validado: false
            }
          }
        }
      }))
      firestore.doc(`fincas/${this.state.finca.nif}`).update(this.state.finca)
      toast.success("El archivo se ha subido correctamente")
    }).catch(error => {
      console.log(error)
      toast.error("Ha ocurrido un error al subir el archivo")
    })
  }

  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }

  }

  loopHvCt = (num, c, fNif, anoFechaVisita) => {
    //console.log(num)
    let y = 0
    let arr = []
    let anos = []
    let rutas = []
    for(let x = anoFechaVisita; x <= num; x++){
      //console.log(x)
      y++
      anos.push(x)
      if(x === 2022 || c.documentos.hojaVisita?.ano === '' || c.documentos.hojaVisita?.ano === undefined){
          rutas.push("/"+fNif+"/"+c.nif+"/hoja-visita.pdf")
      }else{
          rutas.push("/"+fNif+"/"+c.nif+"/hoja-visita_"+x+".pdf")
      }
    }
    
    for(let z = 0; z < y; z++){
        arr.push(<tr>
            <td>{c.documentos.hojaVisita?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : c.documentos.hojaVisita?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
            <td style={{ width: '100%' }}>Hoja de visita {anos[z]}</td>
            <td style={{ textAlign: 'center' }}></td>
            <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(rutas[z])} /></td>
        </tr>)
    }
    return arr
  }

  loopHv = async (num, f) => {
    let anoFechaVisita = this.state.anoFechaVisita
    if(num < this.state.anoFechaVisita){
      anoFechaVisita = num
    }
    let y = 0
    let arr = []
    let anos = []
    let rutas = []
    
    for(let x = anoFechaVisita; x <= num; x++){

      let ruta = f.documentos.hojaVisita?.ano === undefined || x === 2022
          ? `/${f.nif}/hoja-visita.pdf`
          : `/${f.nif}/hoja-visita_${x}.pdf`;
      
      try {
        // Utilizamos await para esperar la resolución de la promesa
        const url = await firebase.storage().ref().child(ruta).getDownloadURL();
        
        // Si el archivo existe, lo agregamos a anos y aumentamos y
        anos.push(x);
        y++;

        // Agregar la ruta al arreglo rutas
        rutas.push(ruta);
      } catch (error) {
        // Si hay un error, no hacemos nada
        console.error("Archivo no encontrado para el año", x, error);
      }
      
    }
    for(let z = 0; z < y; z++){
        arr.push(<tr>
            <td>{this.state.finca.documentos.hojaVisita?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.hojaVisita?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
            <td style={{ width: '100%' }}>Hoja de visita {anos[z]}</td>
            <td style={{ textAlign: 'center' }}></td>
            <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(rutas[z])} /></td>
        </tr>)
    }
    return arr
  }

  render() {
    if (!this.state.finca) return null //Loading data...
    return <>
      <div className="pageHeader"  >Comunidad / <b>Vecinos {this.state.finca.razonSocial}</b></div>
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS COMUNIDAD</div>
          <div className="datosGrid">
            <p><b>Nombre comunidad:</b> {this.state.finca.razonSocial}</p>
            <p><b>Dirección:</b> {this.state.finca.direccion}</p>
            <p><b>NIF:</b> {this.state.finca.nif}</p>
            <p><b>Municipio:</b> {this.state.finca.municipio}</p>
            <p><b>Persona de contacto:</b> {this.state.finca.aaff.nombre}</p>
            <p><b>CP:</b> {this.state.finca.codigoPostal}</p>
            <p><b>Email:</b> {this.state.finca.aaff.email}</p>
            <p><b>Provincia:</b> {this.state.finca.provincia}</p>
            <p><b>Telefono:</b> {this.state.finca.aaff.telefono}</p>
            {/* <p><b>Fecha de alta:</b> {new Date(this.state.finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                <th></th>
                <th></th>
                <th>Adjuntar</th>
                <th style={{ margin: 5 }}>Ver</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.state.finca.documentos.lopd?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.lopd?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color={'#FF5252'} className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Certificado LOPD</td>
                <td style={{ textAlign: 'center' }}><label htmlFor="lopd"><FiUploadCloud size={30} className="tableIcon" /></label><input type="file" id="lopd" onChange={this.subirLOPD} className="inputfile" /></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.lopd?.ruta)} /></td>
              </tr>
              {(this.state.finca.centrosTrabajo === undefined || this.state.finca.centrosTrabajo.length === 0) ? 
              <>
                {!this.state.finca.documentos.hojaVisita?.pendiente && !this.state.finca.documentos.hojaVisita?.validado ? 
                  <tr>
                    <td>{this.state.finca.documentos.hojaVisita?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.hojaVisita?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%' }}>Hoja de visita</td>
                    <td style={{ textAlign: 'center' }}></td>
                    <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.hojaVisita?.ruta)} /></td>
                  </tr>
                : 
                  this.state.hojasDeVisitaHTML
                }
                {/* {this.state.existeHojaVisita === false ? 
                  <tr>
                    <td>{this.state.finca.documentos.prevencionRiesgos?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.prevencionRiesgos?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                    <td style={{ width: '100%' }}>Plan de prevención</td>
                    <td style={{ textAlign: 'center' }}></td>
                    <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.prevencionRiesgos?.ruta)} /></td>
                  </tr>
                : null} */} 
                <tr>
                  <td>{this.state.finca.documentos.evaluacionRiesgos?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.evaluacionRiesgos?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                  <td style={{ width: '100%' }}>Identificación de riesgos y medidas preventivas</td>
                  <td style={{ textAlign: 'center' }}></td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.existeHojaVisita ? 
                      <Link to={`/comunidades/${this.props.params.nif}/evaluacion-riesgos`} target={'_blank'}><IoDocumentTextOutline size={30} className="tableIcon" /></Link>
                    :
                      <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.evaluacionRiesgos?.ruta)} />
                    }
                  </td>
                </tr>
                <tr>
                  <td>{this.state.finca.documentos.planEmergencia?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.planEmergencia?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                  <td style={{ width: '100%' }}>Plan de emergencia</td>
                  <td style={{ textAlign: 'center' }}></td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.existeHojaVisita ? 
                      <Link to={`/comunidades/${this.props.params.nif}/PlanEmergencia`} target={'_blank'}><IoDocumentTextOutline size={30} className="tableIcon" /></Link>
                    :
                      <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.planEmergencia?.ruta)} />
                    }
                  </td>
                </tr>
              </>
              : 
              null
              }
              
            </tbody>
          </table>
          {(this.state.finca.centrosTrabajo === undefined || this.state.finca.centrosTrabajo.length === 0) ? null :
            <>
              <div className="gridSubhead">CENTROS DE TRABAJO</div>
              <table style={{ margin: 15 }}>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style={{ margin: 5 }}>Ver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.finca.centrosTrabajo.map((c,j) => {
                    return (
                      <>
                        <tr>
                          <td colSpan="8" style={{backgroundColor: '#fffbf4', padding: '10px'}}>{c.nombre}</td>
                        </tr>
                        {/* <tr>
                          <td>{c.documentos.hojaVisita?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : c.documentos.hojaVisita?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                          <td style={{ width: '100%' }}>Hoja de visita</td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.hojaVisita?.ruta)} /></td>
                        </tr> */}
                        {this.loopHvCt(c.documentos.hojaVisita?.ano === undefined ? 2022 : c.documentos.hojaVisita?.ano, c, this.state.finca.nif, this.state.anoFechaVisitaCt)}
                        {/* <tr>
                          <td>{c.documentos.prevencionRiesgos?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : c.documentos.prevencionRiesgos?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                          <td style={{ width: '100%' }}>Plan de prevención</td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.prevencionRiesgos?.ruta)} /></td>
                        </tr> */}
                        <tr>
                          <td>{c.documentos.evaluacionRiesgos?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : c.documentos.evaluacionRiesgos?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                          <td style={{ width: '100%' }}>Identificación de riesgos y medidas preventivas</td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}>
                            {this.state.existeHojaVisita ? 
                              <Link to={`/comunidades/${this.props.params.nif}/${c.nif}/evaluacion-riesgos`} target={'_blank'}><IoDocumentTextOutline size={30} className="tableIcon" /></Link>
                            :
                              <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.evaluacionRiesgos?.ruta)} />
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>{c.documentos.planEmergencia?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : c.documentos.planEmergencia?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                          <td style={{ width: '100%' }}>Plan de emergencia</td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}>
                            {this.state.existeHojaVisita ? 
                              <Link to={`/comunidades/${this.props.params.nif}/${c.nif}/PlanEmergencia`} target={'_blank'}><IoDocumentTextOutline size={30} className="tableIcon" /></Link>
                            :
                              <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.planEmergencia?.ruta)} />
                            }
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </>
          }
          
        </div>
        <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                <th></th>
                {/* <th>Aviso</th> */}
              </tr>
            </thead>
            <tbody>
              {this.state.proveedores.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}><Link to={"/proveedores/" + e.nif}>{e.razonSocial}<p style={{ opacity: 0.55, fontSize: 14 }}>{serviciosProveedores[e.servicio]}</p></Link></td>
                    {/* <td style={{ textAlign: 'center' }}><img src={Aviso} alt="Aviso" className="tableIcon" /></td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }
}

export default withRouter(Comunidad);
