import React from "react";
import { Button, FormControl, FormLabel, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { firestore, storage, auth } from "../../../providers/firebase";
import firebase from "firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";

class HojaVisita extends React.Component {
  constructor() {
    super()
    this.state = {
      finca: undefined,
      nPlantas: undefined,
      comentarios: undefined,
      tecnico: undefined,
      nombreTecnico: undefined,
      fechaVisita: undefined,
      fechaVisitaRenovacion: undefined,
      imagen: undefined,
      imagenURL: undefined,
      aaff: undefined,
      objHojaVisita: undefined,
      imgCargada: false,
      img: undefined,
      porcentajeVisitado: 0,
      nFincas: 0,
      porcentajeVisitadoAAFF: 0,
      nFincasAAFF: 0,
      guardando: false,
      notificadoCAE: false,
      tecnicoACAE:{},
      tecnicoAAAFF:{},
      centroTrabajo:{},
      imgs: undefined,
      imagenes:[],
      imagenesUrl:[],
      imagenesToRemove:[],
      vaciarArr:false,
      tipo: "",
      imagenComprimida: undefined,
      imagenComprimidaNombre: undefined,
      imagenesComprimidas:[],
      imagenesComprimidasNombres:[],
      existeHv:undefined,
      obs1_1: "",
      obs1_2: "",
      obs1_3: "",
      descripcion: ""
    }
  }
  componentDidMount() {
      firestore.collection("hojasVisita").doc(this.props.params.nif).get().then(hv => {
        if(hv.data()?.centrosTrabajo !== undefined){
          hv.data().centrosTrabajo.forEach(c => {
            if(c.nif === this.props.params.centroTrabajo){
              if(c !== undefined){
                if(c.imagenes?.length > 0 && c.imagenes !== undefined){
                  this.setState({imagenesToRemove: c.imagenes})
                  c.imagenes.forEach(i => {
                    storage.ref(i).getDownloadURL().then(img => {
                      this.setState({imagenesUrl: ([...this.state.imagenesUrl, img])})
                    })
                  })
                  //console.log(this.state.imagenesUrl)
                }
                if(c.img !== '' || c.img !== undefined){
                  storage.ref(c.img).getDownloadURL().then(img => {
                    
                      this.setState({imagenURL: img, imgCargada: true})
                      document.getElementById("imgComunidad").removeAttribute('required')
                    
                  })
                }
                var d = undefined
                if(c.fechaVisitaRenovacion === undefined){
                  d = new Date(0)
                }else{
                  new Date(c.fechaVisitaRenovacion?.seconds*1000)
                }
                var d = new Date(c.fechaVisitaRenovacion?.seconds*1000)
                var mm = d.getMonth() + 1 <10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1;
                var dd = d.getDate() <10 ? '0'+d.getDate():d.getDate();
                var yy = d.getFullYear();
                let nuevoFormatoRenovada = c.fechaVisitaRenovacion === undefined ? new Date(0) : yy+"-"+mm+"-"+dd

                //this.setState({objHojaVisita: hv.data()})
                var date = new Date(c.fechaVisita?.seconds*1000)
                var mm = date.getMonth() + 1 <10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1;
                var dd = date.getDate() <10 ? '0'+date.getDate():date.getDate();
                var yy = date.getFullYear();
                let nuevoFormatoVisita = yy+"-"+mm+"-"+dd
                this.setState({
                  existeHv: c.exists,
                  fechaVisita: nuevoFormatoVisita,
                  fechaVisitaRenovacion: nuevoFormatoRenovada,
                  nPlantas: c.nPlantas,
                  finca: hv.data().comunidad,
                  tecnico: hv.data().tecnico.nif,
                  nombreTecnico: hv.data().tecnico.nombre,
                  aaff: {
                    nif: hv.data().aaff.nif,
                    razonSocial: hv.data().aaff.razonSocial
                  },
                  img: c.img,
                  tipo: c.tipo === undefined ? '' : c.tipo,
                  p1_0: c.p1_0,
                  p1_1: c.p1_1,
                  p1_2: c.p1_2,
                  p1_3: c.p1_3,
                  p1_4: c.p1_4,
                  p1_5: c.p1_5,
                  p1_6: c.p1_6,
                  p1_7: c.p1_7,
                  p2_0: c.p2_0,
                  p2_1: c.p2_1,
                  p3_0: c.p3_0,
                  p3_1: c.p3_1,
                  p3_2: c.p3_2,
                  p3_3: c.p3_3,
                  p4_0: c.p4_0,
                  p4_1: c.p4_1,
                  p4_2: c.p4_2,
                  p4_3: c.p4_3,
                  p4_4: c.p4_4,
                  p4_5: c.p4_5,
                  p5_0: c.p5_0,
                  p5_1: c.p5_1,
                  p5_2: c.p5_2,
                  p6_0: c.p6_0,
                  p6_1: c.p6_1,
                  p6_2: c.p6_2,
                  p7_0: c.p7_0,
                  p7_1: c.p7_1,
                  p7_2: c.p7_2,
                  p7_3: c.p7_3,
                  p8_0: c.p8_0,
                  p8_1: c.p8_1,
                  p8_2: c.p8_2,
                  p8_2: c.p8_3,
                  p9_0: c.p9_0,
                  p9_1: c.p9_1,
                  p9_2: c.p9_2,
                  p9_3: c.p9_3,
                  p9_4: c.p9_4,
                  p9_5: c.p9_5,
                  p9_6: c.p9_6,
                  p9_7: c.p9_7,
                  p9_8: c.p9_8,
                  p9_9: c.p9_9,
                  p9_10: c.p9_10,
                  p9_11: c.p9_11,
                  p9_12: c.p9_12,
                  p9_13: c.p9_13,
                  comentarios: c.comentarios,
                  descripcion: c.descripcion
                })
                this.tecFincasCAE()
                this.tecFincasAAFF()
              }else{
                this.setState({imgCargada:false})
              }
            }
          })
        }
        
      })
      let nifTecnico = ''
      let nifAAFF = ''
      let centroTrabajo = {}
    firestore.collection("fincas").doc(this.props.params.nif).get().then(finca => {
      firestore.collection("aaff").doc(finca.data().nifAAFF).get().then(aaff => {
        firestore.collection("tecnicos").doc(aaff.data().nifTecnico).get().then(tecnico => {
          nifTecnico = tecnico.data().nif
          nifAAFF = aaff.data().nif
          finca.data().centrosTrabajo.forEach(c => {
              if(c.nif === this.props.params.centroTrabajo){
                  centroTrabajo = c
            } 
          })
          this.setState({
            finca: finca.data(),
            centroTrabajo: centroTrabajo,
            tecnico: tecnico.data().nif,
            nombreTecnico: tecnico.data().nombre + ' ' + tecnico.data().apellidos,
            notificadoCAE: tecnico.data().notificadoCAE === undefined ? false : tecnico.data().notificadoCAE,
            
            aaff: {
              nif: aaff.data().nif,
              razonSocial: aaff.data().razonSocial,
              notificadoAAFF: aaff.data().notificadoAAFF === undefined ? false : aaff.data().notificadoAAFF,
              email: aaff.data().email
            }
          })
        })
      })
    })
  }

  tecFincasCAE(){ /* PORCENTAJE DE TODAS LAS FINCAS VISITADAS RESPECTO A LAS TOTALES ASIGNADAS  */
    let promises1 = []
    let data = {}
    let nfincas = 0 
    firestore.collection('hojasVisita').where('tecnico.nif', '==', auth.currentUser.uid).get().then(hv => {
      data.fVisitadas = hv.docs.length
    })
    firestore.collection('aaff').where('nifTecnico', '==', auth.currentUser.uid).get().then(aaff => {
      aaff.docs.forEach(a => {
        promises1.push(firestore.collection('fincas').where('nifAAFF', '==', a.data().nif).get().then(finca => {
          nfincas = nfincas + finca.docs.length
          data.nfincas = nfincas
        }))
      })
      Promise.all(promises1).then(() => {
        data.fincasSinVisita = data.nfincas-data.fVisitadas
        data.porcentaje = (data.fVisitadas/data.nfincas)*100
        this.setState({tecnicoACAE: data})
      })
    })
  }
  tecFincasAAFF(){ /* PORCENTAJE DE TODAS LAS FINCAS VISITADAS RESPECTO A UN AAFF */
    let promises1 = []
    let data = {}
    let nfincas = 0  
    setTimeout(() => {
      let aaffNif = this.state.aaff.nif
      firestore.collection('hojasVisita').where('tecnico.nif', '==', auth.currentUser.uid).where('aaff.nif', '==', aaffNif).get().then(hv => {
        data.fVisitadas = hv.docs.length
      })
      promises1.push(firestore.collection('fincas').where('nifAAFF', '==', aaffNif).get().then(finca => {
        nfincas = nfincas + finca.docs.length
        data.nfincas = nfincas
      }))
      Promise.all(promises1).then(() => {
        data.fincasSinVisita = data.nfincas-data.fVisitadas
        data.porcentaje = (data.fVisitadas/data.nfincas)*100
        this.setState({tecnicoAAAFF: data})
      })
    }, 5000)
    
  }
  handleChangeTipo = e => {
    const { name, value } = e.target;
    const descripcion = this.getTipo(value);
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      descripcion: descripcion, // Actualiza la descripción basada en el tipo
    }));
    if(e.target.value === "GARAJE"){
      var elts = document.getElementsByClassName("conds");
      for(var e = 0; e < elts.length; e++) { // For each element
        var elt = elts[e];
        elt.removeAttribute("required");
      }
    }else{
      var elts = document.getElementsByClassName("conds");
      for(var e = 0; e < elts.length; e++) { // For each element
        var elt = elts[e];
        elt.setAttribute("required", ""); 
      }
    }
  }
  getTipo = (tipo) => {
    switch(tipo){
      case 'COMUNIDAD DE PROPIETARIOS':
        return 'La comunidad de propietarios está compuesta por el edificio de viviendas'
      case 'COMUNIDAD DE PROPIETARIOS Y GARAJE':
        return 'La comunidad de propietarios está compuesta por el edificio de viviendas y el parking'
      case 'GARAJE':
        return 'La comunidad de propietarios está compuesta por el parking exclusivamente'
      default:
        return ""
    }
  }

  handleChange = e => {
    //console.log(this.state.finca)
    this.setState({
      //...this.state.objHojaVisita,
      [e.target.name]: e.target.value
    })
    if (e.target.files?.[0]) {
      //console.log(e.target.name)
       if(e.target.name === 'imagen'){
        let nombre = e.target.files[0].name
        nombre = nombre.split('.');
        let nuevoNombre = nombre[0]+"_"+Date.now()+"."+nombre[1]
        this.comprimirImagen(e.target.files[0], 14).then(res =>{
          /* let imccompr = []
          imccompr.push({...res, name: e.target.files[0].name}) */
          this.setState({
            imagenComprimida: res,
            imagenComprimidaNombre: nuevoNombre,
            imagenURL: URL.createObjectURL(res),
            imgCargada: true
          })
        })
        /* this.setState({
          imagenURL: URL.createObjectURL(e.target.files[0]),
          imgCargada: true
        }) */
      }
      if(e.target.name === 'imagenes'){
        if(!this.state.vaciarArr){
          this.state.imagenes.splice(0, this.state.imagenes.length)
          this.state.imagenesUrl.splice(0, this.state.imagenesUrl.length)
          this.setState({vaciarArr:true})
        }
        let nombres = e.target.files[0].name
        nombres = nombres.split('.');
        let nuevosNombres = nombres[0]+"_"+Date.now()+"."+nombres[1]
        this.comprimirImagen(e.target.files[0], 14).then(res1 =>{
          this.setState({
            imagenesComprimidas: ([...this.state.imagenesComprimidas, res1]),
            imagenesComprimidasNombres: ([...this.state.imagenesComprimidasNombres, nuevosNombres]),
            imagenes: ([...this.state.imagenes, res1]),
            imagenesUrl: ([...this.state.imagenesUrl, URL.createObjectURL(res1)])
          })
        })
        /* this.setState({
          imagenes:([...this.state.imagenes, e.target.files[0]]),
          imagenesUrl: ([...this.state.imagenesUrl, URL.createObjectURL(e.target.files[0])])
        })  */
        //console.log(this.state.imagenes)
      }
    }
    //console.log(this.state.imagenesToRemove)
  }

  comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
		/*
			https://parzibyte.me/blog
		*/
		return new Promise((resolve, reject) => {
			const $canvas = document.createElement("canvas");
			const imagen = new Image();
			imagen.onload = () => {
				$canvas.width = imagen.width;
				$canvas.height = imagen.height;
				$canvas.getContext("2d").drawImage(imagen, 0, 0);
				$canvas.toBlob(
					(blob) => {
						if (blob === null) {
							return reject(blob);
						} else {
							resolve(blob);
						}
					},
					"image/jpeg",
					porcentajeCalidad / 100,
				);
			};
			imagen.src = URL.createObjectURL(imagenComoArchivo);
		});
	};

  handleSubmit = (event) => {
    event.preventDefault()
    const toastInfo = toast.info("Guardando Hoja de visita",{autoClose:false})
    this.setState({ guardando: true })
    /* let nombreImg = ''
    let ruta = ''
    if(this.state.imgCargada){
      const n = this.state.img.split("/")
      nombreImg = n[2].split('.')
      ruta = this.state.img
    }else{
      let img = document.getElementById("imgComunidad").files[0]
      ruta = "hojasVisita/" + this.state.finca.nif + "/" + img["name"]
      nombreImg = img
      
    } */
    let promises=[]
    let imgsUrl=[]
    let imgPrincipal = undefined
    let imgPrincipalNombre = undefined
    /* console.log(document.getElementById("imgComunidad").files[0])
    console.log(this.state.imagenURL) */
    if(document.getElementById("imgComunidad").files[0]?.name !==undefined ){
      imgPrincipal = this.state.imagenComprimida
      imgPrincipalNombre = this.state.imagenComprimidaNombre
      promises.push( 
        storage.ref("hojasVisita/" + this.state.finca.nif + "/" + this.state.centroTrabajo.nif + "/" + imgPrincipalNombre).put(imgPrincipal).then(snapshot => {
          this.setState({img: snapshot.ref.fullPath})
        }))
    }
    promises.push(
      this.state.imagenesComprimidas.forEach((img, i) => {
      //console.log(img)
      storage.ref("hojasVisita/" + this.state.finca.nif + "/" + this.state.centroTrabajo.nif + "/" + this.state.imagenesComprimidasNombres[i]).put(img).then(snapshot => {
        //console.log(snapshot.ref.fullPath)
        imgsUrl.push(snapshot.ref.fullPath)
      })
    }))
    return Promise.all(promises).then(() => {
      let promises1 = []
      this.setState({imagenesUrl: imgsUrl})
      let q = {}
      if (this.state['p2_0'] === "s") {
        q['p2_1'] = this.state['p2_1']
      }
      if (this.state['p3_0'] === "s") {
        q['p3_1'] = this.state['p3_1']
        q['p3_2'] = this.state['p3_2']
        q['p3_3'] = this.state['p3_3']
      }
      if (this.state['p4_0'] === "s") {
        q['p4_1'] = this.state['p4_1']
        q['p4_2'] = this.state['p4_2']
        q['p4_3'] = this.state['p4_3']
        q['p4_4'] = this.state['p4_4']
        q['p4_5'] = this.state['p4_5']
        q['p4_6'] = this.state['p4_6']
        q['p4_7'] = this.state['p4_7']
        q['p4_8'] = this.state['p4_8']
        q['p4_9'] = this.state['p4_9']
        q['p4_10'] = this.state['p4_10']
        q['p4_11'] = this.state['p4_11']
        q['p4_12'] = this.state['p4_12']
      }
      if (this.state['p5_0'] === "s") {
        q['p5_1'] = this.state['p5_1']
        q['p5_2'] = this.state['p5_2']          
      }
      if (this.state['p6_0'] === "s") {
        q['p6_1'] = this.state['p6_1']
        q['p6_2'] = this.state['p6_2']
      }
      if (this.state['p7_0'] === "s") {
        q['p7_1'] = this.state['p7_1']
        q['p7_2'] = this.state['p7_2']
        q['p7_3'] = this.state['p7_3']
      }
      if (this.state['p8_0'] === "s") {
        q['p8_1'] = this.state['p8_1']
        q['p8_2'] = this.state['p8_2']
        q['p8_3'] = this.state['p8_3']
      }
      if (this.state['p9_0'] === "s") {
        q['p9_1'] = this.state['p9_1']
        q['p9_2'] = this.state['p9_2']
        q['p9_3'] = this.state['p9_3']
        q['p9_4'] = this.state['p9_4']
        q['p9_5'] = this.state['p9_5']
        q['p9_6'] = this.state['p9_6']
        q['p9_7'] = this.state['p9_7']
        q['p9_8'] = this.state['p9_8']
        q['p9_9'] = this.state['p9_9']
        q['p9_10'] = this.state['p9_10']
        q['p9_11'] = this.state['p9_11']
        q['p9_12'] = this.state['p9_12']
      }
      let toRemove = []
      promises1.push(firestore.collection("hojasVisita").doc(this.state.finca.nif).get().then(snap => {
        if(snap.data()?.centrosTrabajo !== undefined ){
          snap.data().centrosTrabajo.forEach(c => {
            if(c.nif === this.props.params.centroTrabajo){
              toRemove.push(c)
            }
          })
          //console.log(toRemove)
        }
      }))
      return Promise.all(promises1).then(() => {
        console.log(this.state)
        setTimeout(() => {
          //Si no se añaden nuevas imagenes se mantienen las anteriores
          if(this.state.imagenesUrl.length===0){
            this.setState({imagenesUrl: this.state.imagenesToRemove})
          }
          //
          //Si existe el centro de trabajo se elimina primero para modificar los nuevos datos.
          if(toRemove.length > 0){
            firestore.collection("hojasVisita").doc(this.state.finca.nif).set({
                centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
            }, {merge: true})
          }
          firestore.collection("hojasVisita").doc(this.state.finca.nif).set({
              
            comunidad: {
              nif: this.state.finca.nif,
              razonSocial: this.state.finca.razonSocial,
              direccion: this.state.finca.direccion,
              localidad: this.state.finca.municipio,
              provincia: this.state.finca.provincia,
              codigoPostal: this.state.finca.codigoPostal,
              telefono: this.state.finca.contacto.telefono,
              proveedores: this.state.finca.proveedores,
            },
            tecnico: {
              nif: this.state.tecnico,
              nombre: this.state.nombreTecnico
            },
            aaff: this.state.aaff,
            centrosTrabajo:
            firebase.firestore.FieldValue.arrayUnion({
              obs1_1: this.state.obs1_1,
              obs1_2: this.state.obs1_2,
              obs1_3: this.state.obs1_3,
              descripcion: this.state.descripcion,
              tipo: this.state.tipo,
              comentarios: this.state.comentarios,
              nPlantas: this.state.nPlantas,
              fechaVisita: new Date(this.state.fechaVisita),
              fechaVisitaRenovacion: this.state.fechaVisitaRenovacion === undefined ? new Date(0) : new Date(this.state.fechaVisitaRenovacion),
              img: this.state.img,
              nombre: this.state.centroTrabajo.nombre,
              nif: this.state.centroTrabajo.nif,
              imagenes: this.state.imagenesUrl,
              p1_0: this.state['p1_0'],
              p1_1: this.state['p1_1'],
              p1_2: this.state['p1_2'],
              p1_3: this.state['p1_3'],
              p1_4: this.state['p1_4'],
              p1_5: this.state['p1_5'],
              p1_6: this.state['p1_6'],
              p1_7: this.state['p1_7'],
              p2_0: this.state['p2_0'],
              p3_0: this.state['p3_0'],
              p4_0: this.state['p4_0'],
              p5_0: this.state['p5_0'],
              p6_0: this.state['p6_0'],
              p7_0: this.state['p7_0'],
              p8_0: this.state['p8_0'],
              p9_0: this.state['p9_0'],
              ...q
              })
          }, {merge: true}).then(() => {
            /* console.log(this.state.imagenesUrl?.length)
            console.log(this.state.imagenesToRemove?.length)
            console.log(this.state.imagenes)
            console.log(this.state.imagenes.length) */
            /* if(this.state.imagenes.length > 0){
              if(this.state.imagenesToRemove?.length > 0 && this.state.imagenesUrl?.length > 0){
                
                firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                  centroTrabajo:{
                    imagenes: firebase.firestore.FieldValue.arrayRemove(...this.state.imagenesToRemove), 
                  }
                })
              }
              //console.log(this.state.imagenesUrl)
              if(this.state.imagenesUrl.length > 0){
                //console.log("entra para subir array")
                firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                  centroTrabajo:{
                    imagenes: firebase.firestore.FieldValue.arrayUnion(...this.state.imagenesUrl),
                  }
                })
              }else{
                firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                  centroTrabajo: {
                    imagenes: [],
                  }
                })
              }
            }else{
              firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                centroTrabajo: {
                  imagenes: [],
                }
              })
            } */
            toast.update(toastInfo, {
              render: "Hoja de visita guardada con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
            this.setState({ guardando: false })
            /* console.log(this.state.finca)
            console.log(this.state.centroTrabajo) */
            const generarHojaVisitaCentroTrabajo = firebase.app().functions('europe-west1').httpsCallable('generarHojaVisitaCentroTrabajo');
            generarHojaVisitaCentroTrabajo({finca: this.state.finca, centroTrabajo: this.state.centroTrabajo })
            .then( result => {
              //console.log(result.data);
              //toast.success("Email enviado con éxito")
            })
  
            let promises2 = []
            let toRemove = []
            let numRenovadasVisitadas = 0
            //console.log(this.state.centroTrabajo)
            this.state.finca.centrosTrabajo.map(ct => {
              //console.log(ct.renovadaVisitada)
              if(ct.renovadaVisitada){
                numRenovadasVisitadas++
              }
            })
            //console.log(this.state.finca.centrosTrabajo.length, numRenovadasVisitadas)
            if(this.state.finca.centrosTrabajo.length === numRenovadasVisitadas){
              firestore.collection('fincas').doc(this.state.finca.nif).update({
                renovadaVisitada: true
              })
            }
            /* promises2.push(firestore.collection("fincas").doc(this.state.finca.nif).get().then(snap => {
              if(snap.data()?.centrosTrabajo !== undefined ){
                snap.data().centrosTrabajo.forEach(c => {
                  if(c.nif === this.props.params.centroTrabajo){
                    console.log(c)
                    toRemove.push(c)
                  }
                })
                //console.log(toRemove)
              }
            }))
            Promise.all(promises2).then(() => {
              
              if(toRemove.length > 0){
                firestore.collection("fincas").doc(this.state.finca.nif).set({
                    centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
                }, {merge: true})
              }
              firestore.collection("fincas").doc(this.state.finca.nif).set({
                  
                centrosTrabajo:
                firebase.firestore.FieldValue.arrayUnion({
                  
                })
              }, {merge: true})
            }) */
  
  
            setTimeout(() => {
              window.location.replace("/comunidades")
            }, 4000);
          })
          .catch((err) => {
            console.log(err)
            toast.error("Hubo un problema al guardar la hoja de visita")
          })
        },500)
      })
      
    })
  }

  render() {
    if (!this.state.finca) return null
    return (
      <>
        <div className="hojaVisitaContainer">
          <h1>Hoja de visita</h1>
          <form onSubmit={this.handleSubmit} style={{ background: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '1px 4px 10px #ccc' }}>
            <h2>Datos de la comunidad</h2>
            <Grid container spacing={3} className="bordeForm">
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>Nombre de la comunidad</FormLabel>
                  <TextField
                    variant="outlined"
                    name="razonSocial"
                    value={this.state.finca.razonSocial+ " - "+ this.state.centroTrabajo.nombre}
                    required 
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>Número de plantas</FormLabel>
                  <TextField
                    variant="outlined"
                    name="nPlantas"
                    required 
                    value={this.state.nPlantas} 
                    onChange={this.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <FormLabel>Tipo de comunidad</FormLabel>
                  <Select
                    value={this.state.tipo} 
                    onChange={this.handleChangeTipo} 
                    name="tipo"
                    required
                  >
                    <MenuItem value="">
                      <em>Seleccionar</em>
                    </MenuItem>
                    <MenuItem value="COMUNIDAD DE PROPIETARIOS">COMUNIDAD DE PROPIETARIOS</MenuItem>
                    <MenuItem value="COMUNIDAD DE PROPIETARIOS Y GARAJE">COMUNIDAD DE PROPIETARIOS Y GARAJE</MenuItem>
                    <MenuItem value="GARAJE">GARAJE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>Descripción del lugar</FormLabel>
                  <TextField
                    variant="outlined"
                    name="descripcion"
                    type="text"
                    disabled 
                    multiline 
                    style={{padding: this.state.descripcion ? '0px 5px' : '0px'}}
                    value={this.state.descripcion}
                    onChange={this.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>{this.state.finca.renovada && this.state.existeHv ? "Fecha de la visita de renovación" : "Fecha de la visita"}</FormLabel>
                  <TextField
                    variant="outlined"
                    name="fechaVisita"
                    type="date"
                    required 
                    value={this.state.finca.renovada && this.state.existeHv ? this.state.fechaVisitaRenovacion : this.state.fechaVisita} 
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl></FormControl>
              </Grid>
            </Grid>
            <h2>Imágenes</h2>
            <Grid container spacing={3} className="bordeForm">
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel></FormLabel>
                  <Button
                  variant="contained"
                  component="label"
                  >
                  Imagen principal de la comunidad
                  <input
                    id="imgComunidad" 
                    name="imagen" 
                    type="file" 
                    required 
                    accept="image/png, image/jpeg" 
                    value={this.state.imagen} 
                    onChange={this.handleChange}
                    hidden
                  />
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel></FormLabel>
                  <Button
                  variant="contained"
                  component="label"
                  >
                  Imagenes de la comunidad
                  <input
                    id="imgsComunidad" 
                    name="imagenes" 
                    type="file" 
                    accept="image/png, 
                    image/jpeg" 
                    value={this.state.imgs} 
                    onChange={this.handleChange}
                    hidden
                  />
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                {this.state.imgCargada ? <div style={{ paddingTop: '66.66%', backgroundImage: `url(${this.state.imagenURL})`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div> : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  {this.state.imagenesUrl.map(i=> {
                    //console.log(i)
                    return ( <img key={i} style={{width:'150px', height:'120px'}} src={i} />)
                  })}
                </div>
              </Grid>
            </Grid>
            <h2>Riesgos del edificio generales</h2>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={5}>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>1.- ¿El estado general del edificio es correcto?</label>
                  <label>
                    <input type="radio" checked={this.state['p1_0'] === 's'} className="conds" name="p1_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_0'] === 'n'} className="conds" name="p1_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p1_0'] !== undefined ?
                  <div  className="subpreguntas show">
                    <div className="pregunta">
                      <label>1.1. ¿Hay riesgo de desprendimiento de baldosas o elementos?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_1'] === 's'} className="conds" name="p1_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_1'] === 'n'} className="conds" name="p1_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    {this.state['p1_1'] === 's' ?
                      <div className={`observacion ${this.state.p1_1 === 's' ? 'show' : ''}`}>
                        <input type="text" placeholder="¿Dónde nos hemos encontrado el riesgo?" name="obs1_1" value={this.state.obs1_1} onChange={this.handleChange} />  
                      </div>
                    : null}
                    <div className="pregunta">
                      <label>1.2. ¿Hay cristales rotos o en mal estado de puertas/ventanas?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_2'] === 's'} className="conds" name="p1_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_2'] === 'n'} className="conds" name="p1_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    {this.state['p1_2'] === 's' ?
                      <div className={`observacion ${this.state.p1_2 === 's' ? 'show' : ''}`}>
                        <input type="text" placeholder="¿Dónde nos hemos encontrado el riesgo?" name="obs1_2" value={this.state.obs1_2} onChange={this.handleChange} />  
                      </div>
                    : null}
                    <div className="pregunta">
                      <label>1.3. ¿Hay cables eléctricos sueltos?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_3'] === 's'} className="conds" name="p1_3" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_3'] === 'n'} className="conds" name="p1_3" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    {this.state['p1_3'] === 's' ?
                      <div className={`observacion ${this.state.p1_3 === 's' ? 'show' : ''}`}>
                        <input type="text" placeholder="¿Dónde nos hemos encontrado el riesgo?" name="obs1_3" value={this.state.obs1_3} onChange={this.handleChange} />  
                      </div>
                    : null}
                    <div className="pregunta">
                      <label>1.4. ¿Las cajas de registro están abiertas?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_4'] === 's'} className="conds" name="p1_4" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_4'] === 'n'} className="conds" name="p1_4" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>1.5. ¿Hay riesgo de deslizamiento en las rampas de acceso?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_5'] === 's'} className="conds" name="p1_5" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_5'] === 'n'} className="conds" name="p1_5" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>1.6. ¿El cuadro eléctrico se encuentra abierto?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_6'] === 's'} className="conds" name="p1_6" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_6'] === 'n'} className="conds" name="p1_6" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>1.7.	¿El cuadro eléctrico se encuentra señalizado?</label>
                      <label>
                        <input type="radio" checked={this.state['p1_7'] === 's'} className="conds" name="p1_7" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p1_7'] === 'n'} className="conds" name="p1_7" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>2.- ¿La comunidad dispone de ascensores?</label>
                  <label>
                    <input type="radio" checked={this.state['p2_0'] === 's'} name="p2_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_0'] === 'n'} name="p2_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p2_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p2_0 === 's' ? 'show' : ''}`}>
                    <div className="pregunta">
                      <label>2.1. ¿Están revisados?</label>
                      <label>
                        <input type="radio" checked={this.state['p2_1'] === 's'} name="p2_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p2_1'] === 'n'} name="p2_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>3.- ¿Dispone de azotea transitable?</label>
                  <label>
                    <input type="radio" checked={this.state['p3_0'] === 's'} name="p3_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p3_0'] === 'n'} name="p3_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p3_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p3_0 === 's' ? 'show' : ''}`}>
                    <label>3.1. ¿El acceso a la azotea se realiza de forma segura?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p3_1'] === 's'} name="p3_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p3_1'] === 'n'} name="p3_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <label>3.2. ¿Si el acceso es mediante escaleras, se accede de forma segura?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p3_2'] === 's'} name="p3_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p3_2'] === 'n'} name="p3_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <label>3.3. ¿Dispone de protección perimetral que impida la caida de personas a diferente nivel?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p3_3'] === 's'} name="p3_3" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p3_3'] === 'n'} name="p3_3" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>
                    <label>4.- ¿Disponen de extintores?</label>
                    <input type="radio" checked={this.state['p4_0'] === 's'} name="p4_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_0'] === 'n'} name="p4_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_0'] === 'np'} name="p4_0" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                {this.state['p4_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p4_0 === 's' ? 'show' : ''}`}>
                    <div className="pregunta">
                      <label>4.1. ¿Dispone de número suficiente de extintores?</label>
                      <label>
                        <input type="radio" checked={this.state['p4_1'] === 's'} name="p4_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p4_1'] === 'n'} name="p4_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>
                        <label>4.2. ¿Están correctamente revisados?</label>
                        <input type="radio" checked={this.state['p4_2'] === 's'} name="p4_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p4_2'] === 'n'} name="p4_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>
                        <label>4.3. ¿Están correctamente señalizados?</label>
                        <input type="radio" checked={this.state['p4_3'] === 's'} name="p4_3" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p4_3'] === 'n'} name="p4_3" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>4.4. ¿Están colgados a una altura adecuada (0.8 - 1.2m)?</label>
                      <label>
                        <input type="radio" checked={this.state['p4_4'] === 's'} name="p4_4" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p4_4'] === 'n'} name="p4_4" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>4.5. ¿Están en buen estado de conservación?</label>
                      <label>
                        <input type="radio" checked={this.state['p4_5'] === 's'} name="p4_5" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p4_5'] === 'n'} name="p4_5" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>5.- ¿Disponen de iluminación de emergencias?</label>
                  <label>
                    <input type="radio" checked={this.state['p5_0'] === 's'} name="p5_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p5_0'] === 'n'} name="p5_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p5_0'] === 'np'} name="p5_0" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                {this.state['p5_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p5_0 === 's' ? 'show' : ''}`}>
                    <div className="pregunta">
                      <label>5.1. ¿Están iluminados los recorridos de evacuación?</label>
                      <label>
                        <input type="radio" checked={this.state['p5_1'] === 's'} name="p5_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p5_1'] === 'n'} name="p5_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>5.2. ¿Están iluminadas las salidas de la finca?</label>
                      <label>
                        <input type="radio" checked={this.state['p5_2'] === 's'} name="p5_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p5_2'] === 'n'} name="p5_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>6.- ¿Disponen de señalización de emergencias?</label>
                  <label>
                    <input type="radio" checked={this.state['p6_0'] === 's'} name="p6_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p6_0'] === 'n'} name="p6_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p6_0'] === 'np'} name="p6_0" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                {this.state['p6_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p6_0 === 's' ? 'show' : ''}`}>
                    <label>6.1. ¿Están señalizadas todas las salidas de la finca?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p6_1'] === 's'} name="p6_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p6_1'] === 'n'} name="p6_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <label>9.2. ¿Están señalizados los recorridos de evacuación?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p6_2'] === 's'} name="p6_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p6_2'] === 'n'} name="p6_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>7.- ¿Dispone de jardin?</label>
                  <label>
                    <input type="radio" checked={this.state['p7_0'] === 's'} name="p7_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p7_0'] === 'n'} name="p7_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p7_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p7_0 === 's' ? 'show' : ''}`}>
                    <div className="pregunta">
                      <label>7.1. ¿Se almacenan productos fitosanitarios?</label>
                      <label>
                        <input type="radio" checked={this.state['p7_1'] === 's'} name="p7_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p7_1'] === 'n'} name="p7_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    {this.state['p7_1'] === "s" ?
                      <>
                        <div className="pregunta">
                          <label>7.2. ¿Se guardan los productos bajo llave?</label>
                          <label>
                            <input type="radio" checked={this.state['p7_2'] === 's'} name="p7_2" value="s" onChange={this.handleChange} required />
                            Sí
                          </label>
                          <label>
                            <input type="radio" checked={this.state['p7_2'] === 'n'} name="p7_2" value="n" onChange={this.handleChange} required />
                            No
                          </label>
                        </div>
                        <div className="pregunta">
                          <label>7.3. ¿Se dispone de las fichas de seguridad de los productos fitosanitarios?</label>
                          <label>
                            <input type="radio" checked={this.state['p7_3'] === 's'} name="p7_3" value="s" onChange={this.handleChange} required />
                            Sí
                          </label>
                          <label>
                            <input type="radio" checked={this.state['p7_3'] === 'n'} name="p7_3" value="n" onChange={this.handleChange} required />
                            No
                          </label>
                        </div>
                      </>
                    : null}
                  </div>
                  : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>8.- ¿Dispone de piscina?</label>
                  <label>
                    <input type="radio" checked={this.state['p8_0'] === 's'} name="p8_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p8_0'] === 'n'} name="p8_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p8_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p8_0 === 's' ? 'show' : ''}`}>
                    <div className="pregunta">
                      <label>8.1. ¿Se utilizan productos para el mantenimiento de la piscina?</label>
                      <label>
                        <input type="radio" checked={this.state['p8_1'] === 's'} name="p8_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p8_1'] === 'n'} name="p8_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <label>8.2. ¿La piscina dispone de protección perimetral?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p8_2'] === 's'} name="p8_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p8_2'] === 'n'} name="p8_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <label>8.3. ¿La piscina dispone de aros salvavidas?</label>
                    <div className="pregunta">
                      <label>
                        <input type="radio" checked={this.state['p8_3'] === 's'} name="p8_3" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p8_3'] === 'n'} name="p8_3" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>          
              <Grid item xs={12} sm={6}>
                <div className="pregunta-principal">
                  <label>9.- ¿Dispone de parking?</label>
                  <label>
                    <input type="radio" checked={this.state['p9_0'] === 's'} name="p9_0" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p9_0'] === 'n'} name="p9_0" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p9_0'] === "s" ?
                  <div className={`subpreguntas ${this.state.p9_0 === 's' ? 'show' : ''}`}>
                    <div className="pregunta">
                      <label>9.1. ¿Dispone de número suficiente de extintores? (aproximadamente uno cada 15 metros de recorrido hasta la salida más cercana)</label>
                      <label>
                        <input type="radio" checked={this.state['p9_1'] === 's'} name="p9_1" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_1'] === 'n'} name="p9_1" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.2. ¿Están correctamente revisados los extintores?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_2'] === 's'} name="p9_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_2'] === 'n'} name="p9_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.3. ¿Están correctamente señalizados los extintores?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_3'] === 's'} name="p9_3" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_3'] === 'n'} name="p9_3" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.4. ¿Están colgados a una altura adecuada los extintores  (0.8m - 1.2m)?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_4'] === 's'} name="p9_4" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_4'] === 'n'} name="p9_4" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.5. ¿Están en buen estado de conservación los extintores?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_5'] === 's'} name="p9_5" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_5'] === 'n'} name="p9_5" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.6. ¿Están señalizados los recorridos de evacuación?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_6'] === 's'} name="p9_6" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_6'] === 'n'} name="p9_6" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.7.	¿Están señalizadas las salidas de emergencia?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_7'] === 's'} name="p9_7" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_7'] === 'n'} name="p9_7" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.8. ¿Están señalizadas las direcciones de circulación (+500m²)?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_8'] === 's'} name="p9_8" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_8'] === 'n'} name="p9_8" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_8'] === 'np'} name="p9_8" value="np" onChange={this.handleChange} required />
                        No Procede
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.9. ¿Están señalizadas las puertas de entrada y salida (2 puertas de acceso)?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_9'] === 's'} name="p9_9" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_9'] === 'n'} name="p9_9" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.10. ¿Dispone de sistemas de extración de humos (+500m² o 2 pisos)?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_10'] === 's'} name="p9_10" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_10'] === 'n'} name="p9_10" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_10'] === 'np'} name="p9_10" value="np" onChange={this.handleChange} required />
                        No procede
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.11. ¿Dispone de BIE's?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_11'] === 's'} name="p9_11" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_11'] === 'n'} name="p9_11" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_11'] === 'np'} name="p9_11" value="np" onChange={this.handleChange} required />
                        No procede
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.12. ¿El cuadro eléctrico está cerrado/protegido?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_12'] === 's'} name="p9_12" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_12'] === 'n'} name="p9_12" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_12'] === 'np'} name="p9_12" value="np" onChange={this.handleChange} required />
                        No procede
                      </label>
                    </div>
                    <div className="pregunta">
                      <label>9.13. ¿Está señalizado el cuadro eléctrico con el panel de "Riesgo eléctrico"?</label>
                      <label>
                        <input type="radio" checked={this.state['p9_13'] === 's'} name="p9_13" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_13'] === 'n'} name="p9_13" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p9_13'] === 'np'} name="p9_13" value="np" onChange={this.handleChange} required />
                        No procede
                      </label>
                    </div>
                  </div>
                  : null}
              </Grid>
            </Grid>
            <h2>Comentarios</h2>
            <Grid container spacing={3}>
              <textarea name="comentarios" required value={this.state.comentarios} onChange={this.handleChange} style={{width:"100%", height:"300px", padding: '20px', backgroundColor:"white"}}></textarea>
            </Grid>
            <div style={{ display: "flex", justifyContent: 'flex-end' }}>
              <input type="submit" value="Guardar" id="guardar" disabled = {this.state.guardando ? "disabled" : ""} style={this.state.guardando ? {opacity: 0.5, cursor: "default"} : {}}></input>
            </div>
          </form>
        </div>
        <style jsx="true">
          {`
            .MuiOutlinedInput-multiline{
              padding: ${this.state.descripcion !== '' ? '9px' : '18.5px 14px'};
              font-size:13px;
            }
            textarea{
              border:1px solid #ccc;
              border-radius: 5px;
            }
            .observacion{
              margin: 10px 0;
              border: 1px solid #ccc;
              border-radius: 5px;
            }
            .observacion input{
              background: #ebebeb;
              font-size: 12px;
            }
          `}
        </style>
      </>
    )
  }
}

export default withRouter(HojaVisita);
