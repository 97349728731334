import React from "react";
import { firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import LogoNF from "../../../images/Logo.png"
import { incidencias } from "../../../providers/incidencias";
import peligro from "../../../images/peligro.png"
import extintor from "../../../images/extintor.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import salida from "../../../images/salida.png"

class HojaVisitaRender extends React.Component {
    constructor() {
        super()
        this.state = {
            hojaVisita: undefined,
            centroTrabajo: undefined,
            incidencias: [],
            imagenes: []
        }
    }
    componentDidMount() {
        firestore.collection("hojasVisita").doc(this.props.params.nif).get().then(doc => {
            const hojaVisita = doc.data()
            let centroTrabajo = undefined
            doc.data().centrosTrabajo.forEach(ct => {
                if(ct.nif === this.props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                    if(centroTrabajo.imagenes !== undefined){
                        console.log(centroTrabajo.imagenes)
                        centroTrabajo.imagenes.forEach(i => {
                            storage.ref(i).getDownloadURL().then(img => {
                                this.setState({imagenes: ([...this.state.imagenes, img])})
                            })
                        })
                    }
                }
            })
            let inc = []
            if (centroTrabajo?.p1_1 === "s") inc.push(incidencias.p1_1)
            if (centroTrabajo?.p1_2 === "s") inc.push(incidencias.p1_2)
            if (centroTrabajo?.p1_3 === "s") inc.push(incidencias.p1_3)
            if (centroTrabajo?.p1_4 === "s") inc.push(incidencias.p1_4)
            if (centroTrabajo?.p1_5 === "s") inc.push(incidencias.p1_5)
            if (centroTrabajo?.p1_6 === "s") inc.push(incidencias.p1_6)
            if (centroTrabajo?.p1_7 === "s") inc.push(incidencias.p1_7)
            if (centroTrabajo?.p1_8 === "n") inc.push(incidencias.p1_8)
            if (centroTrabajo?.p2_0 === "s") {
                if (centroTrabajo?.p2_1 === "n") inc.push(incidencias.p2_1)
                if (centroTrabajo?.p2_2 === "n") inc.push(incidencias.p2_2)
                if (centroTrabajo?.p2_3 === "n") inc.push(incidencias.p2_3)
                if (centroTrabajo?.p2_4 === "n") inc.push(incidencias.p2_4)
                if (centroTrabajo?.p2_5 === "n") inc.push(incidencias.p2_5)
                if (centroTrabajo?.p2_5 === "n") {
                    if (centroTrabajo?.p2_6 === "n") inc.push(incidencias.p2_6)
                    if (centroTrabajo?.p2_7 === "n") inc.push(incidencias.p2_7)
                    if (centroTrabajo?.p2_8 === "n" && centroTrabajo?.p2_9 === "n") inc.push(incidencias.p2_8)
                }
                if (centroTrabajo?.p2_9 === "n" && centroTrabajo?.p2_8 === "n") inc.push(incidencias.p2_9)
                if (centroTrabajo?.p2_10 === "n") inc.push(incidencias.p2_10)
            }
            if (centroTrabajo?.p3_0 === "s") {
                if (centroTrabajo?.p3_1 === "n") inc.push(incidencias.p3_1)
                if (centroTrabajo?.p3_2 === "n") inc.push(incidencias.p3_2)
                if (centroTrabajo?.p3_3 === "n") inc.push(incidencias.p3_3)
                if (centroTrabajo?.p3_4 === "n") inc.push(incidencias.p3_4)
            }
            if (centroTrabajo?.p4_0 === "s") {
                if (centroTrabajo?.p4_1 === "n") inc.push(incidencias.p4_1)
                if (centroTrabajo?.p4_2 === "n") inc.push(incidencias.p4_2)
                if (centroTrabajo?.p4_3 === "n") inc.push(incidencias.p4_3)
                if (centroTrabajo?.p4_4 === "n") inc.push(incidencias.p4_4)
                if (centroTrabajo?.p4_5 === "n") inc.push(incidencias.p4_5)
                if (centroTrabajo?.p4_6 === "n") inc.push(incidencias.p4_6)
                if (centroTrabajo?.p4_7 === "n") inc.push(incidencias.p4_7)
                if (centroTrabajo?.p4_8 === "n") inc.push(incidencias.p4_8)
                if (centroTrabajo?.p4_9 === "n") inc.push(incidencias.p4_9)
                if (centroTrabajo?.p4_10 === "n") inc.push(incidencias.p4_10)
                if (centroTrabajo?.p4_11 === "n") inc.push(incidencias.p4_11)
                if (centroTrabajo?.p4_12 === "n") inc.push(incidencias.p4_12)
                if (centroTrabajo?.p4_13 === "n") inc.push(incidencias.p4_13)
                if (centroTrabajo?.p4_14 === "n") inc.push(incidencias.p4_14)
                if (centroTrabajo?.p4_15 === "n") inc.push(incidencias.p4_15)
                if (centroTrabajo?.p4_16 === "n") inc.push(incidencias.p4_16)
                if (centroTrabajo?.p4_17 === "n") inc.push(incidencias.p4_17)
            }
            if (centroTrabajo?.p5_0 === "s") {
                if (centroTrabajo?.p5_2 === "n") inc.push(incidencias.p5_2)
                if (centroTrabajo?.p5_3 === "n") inc.push(incidencias.p5_3)
            }
            if (centroTrabajo?.p6_0 === "s") {
                if (centroTrabajo?.p6_2 === "n") inc.push(incidencias.p6_2)
                if (centroTrabajo?.p6_3 === "n") inc.push(incidencias.p6_3)
            }
            if (centroTrabajo?.p7_0 === "s") {
                if (centroTrabajo?.p7_1 === "n") inc.push(incidencias.p7_1)
                if (centroTrabajo?.p7_2 === "n") inc.push(incidencias.p7_2)
                if (centroTrabajo?.p7_3 === "n") inc.push(incidencias.p7_3)
            }
            if (centroTrabajo?.p8_0 === "s") {
                if (centroTrabajo?.p8_1 === "n") inc.push(incidencias.p8_1)
                if (centroTrabajo?.p8_2 === "n") inc.push(incidencias.p8_2)
                if (centroTrabajo?.p8_3 === "n") inc.push(incidencias.p8_3)
                if (centroTrabajo?.p8_4 === "n") inc.push(incidencias.p8_4)
                if (centroTrabajo?.p8_5 === "n") inc.push(incidencias.p8_5)
            }
            if (centroTrabajo?.p9_0 === "s") {
                if (centroTrabajo?.p9_1 === "n") inc.push(incidencias.p9_1)
                if (centroTrabajo?.p9_2 === "n") inc.push(incidencias.p9_2)
            }
            if (centroTrabajo?.p10_0 === "s") {
                if (centroTrabajo?.p10_1 === "n") inc.push(incidencias.p10_1)
                // if (centroTrabajo?.p10_2 === "n") inc.push(incidencias.p10_2)
                if (centroTrabajo?.p10_3 === "n") inc.push(incidencias.p10_3)
                if (centroTrabajo?.p10_4 === "n") inc.push(incidencias.p10_4)
                if (centroTrabajo?.p10_5 === "n") inc.push(incidencias.p10_5)
                if (centroTrabajo?.p10_6 === "n") inc.push(incidencias.p10_6)
            }
            if (centroTrabajo?.p10_0 === "n") inc.push(incidencias.p10_0)
            if (centroTrabajo?.p11_0 === "s") {
                if (centroTrabajo?.p11_1 === "n") inc.push(incidencias.p11_1)
                if (centroTrabajo?.p11_2 === "n") inc.push(incidencias.p11_2)
            }
            if (centroTrabajo?.p11_0 === "n") inc.push(incidencias.p11_0)
            if (centroTrabajo?.p12_0 === "s") {
                if (centroTrabajo?.p12_1 === "n") inc.push(incidencias.p12_1)
                if (centroTrabajo?.p12_2 === "n") inc.push(incidencias.p12_2)
            }
            if (centroTrabajo?.p12_0 === "n") inc.push(incidencias.p12_0)

            let fechaVisita = new Date(centroTrabajo?.fechaVisita.seconds * 1000)
            let fechaVisitaRenovacion = new Date(centroTrabajo?.fechaVisitaRenovacion.seconds * 1000)
            hojaVisita['diffFechas'] = hojaVisita?.fechaVisitaRenovacion?.seconds < hojaVisita?.fechaVisita?.seconds
            hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
            hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
            this.setState({ hojaVisita: hojaVisita, centroTrabajo: centroTrabajo, incidencias: inc })
        }).catch(() => window.location.replace('/'))
    }

    render() {
        if (!this.state.hojaVisita) return null
        return <>
            <table className="tablePage">
                <thead><tr><td style={{ border: 'none' }}>
                    <div className="header-space">&nbsp;</div>
                </td></tr></thead>
                <tbody>
                    <div style={{ padding: '0 30px' }}>
                        {/* <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>HOJA DE SEGUIMIENTO</div> */}
                        <table>
                            <tbody>
                                <tr>
                                    <th className="header1" colSpan={2}>INFORMACIÓN DEL CENTRO DE TRABAJO</th>
                                </tr>
                                <tr>
                                    <th className="cel1">Nombre del centro de trabajo</th>
                                    <td className="cel2">{this.state.hojaVisita?.comunidad.razonSocial}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">CIF del centro de trabajo</th>
                                    <td className="cel2">{this.state.hojaVisita?.comunidad.nif}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Tipo de comunidad</th>
                                    <td className="cel2">{this.state.hojaVisita?.tipo}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Fecha de visita</th>
                                    <td className="cel2">{this.state.hojaVisita?.fechaVisitaRenovacion === undefined || this.state.hojaVisita?.diffFechas === true ? this.state.hojaVisita?.fechaVisita : this.state.hojaVisita?.fechaVisitaRenovacion}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Dirección</th>
                                    <td className="cel2">{this.state.hojaVisita?.comunidad.direccion}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Localidad</th>
                                    <td className="cel2">{this.state.hojaVisita?.comunidad.localidad}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Provincia</th>
                                    <td className="cel2">{this.state.hojaVisita?.comunidad.provincia}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Breve descripción del lugar</th>
                                    <td className="cel2">{this.state.hojaVisita?.descripcion}</td>
                                </tr>
                                <tr>
                                    <th className="cel1">Administrador de Fincas:</th>
                                    <td className="cel2">{this.state.hojaVisita?.aaff.razonSocial}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{marginTop:30}}>
                            <tr>
                                <th className="header1">RIESGO</th>
                                <th className="header1">MEDIDA CORRECTORA</th>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>1- ¿El estado general del edificio es correcto?</th>
                            </tr>
                            <tr>
                                <td className="cel">1.1. Hay riesgo de desprendimiento de baldosas o elementos. Lugar de la incidencia:</td>
                                <td className="cel">Reparar los desperfectos encontrados en el suelo.</td>
                            </tr>
                            <tr>
                                <td className="cel">1.2. Hay cristales rotos o en mal estado de puertas/ventanas. Lugar de la incidencia</td>
                                <td className="cel">Sustituir los cristales afectados, por otros en correcto estado.</td>
                            </tr>
                            <tr>
                                <td className="cel">1.3. Hay cables eléctricos sueltos. Lugar de la incidencia:</td>
                                <td className="cel">Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. En especial las que crucen zonas de paso.</td>
                            </tr>
                            <tr>
                                <td className="cel">1.4. Las cajas de registro se encuentran abiertas.</td>
                                <td className="cel">
                                    <span>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). </span>
                                    <br /><br/>
                                    <span>Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico.</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="cel">1.5. Hay riesgo de deslizamiento en las rampas de acceso.</td>
                                <td className="cel">Se recomienda la instalación de tiras antideslizantes o realizar tratamiento del pavimento.</td>
                            </tr>
                            <tr>
                                <td className="cel">1.6. El cuadro eléctrico se encuentra abierto.</td>
                                <td className="cel">Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</td>
                            </tr>
                            <tr>
                                <td className="cel">1.7. El cuadro eléctrico no se encuentra señalizado.</td>
                                <td className="cel">
                                    <span>Se recomienda señalizar el cuadro eléctrico alertando del riesgo que puede suponer su manipulación. La señalización del cuadro eléctrico es obligatoria de acuerdo al Código Técnico de la Edificación en edificios posteriores al 2006.</span>
                                    <br/><br/>
                                    <span style={{width: '100%', display: 'block', textAlign:'center'}}>
                                        <img src={peligro} alt="Peligro" style={{ width:'50px' }} />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>2- ¿La comunidad dispone de ascensores?</th>
                            </tr>
                            <tr>
                                <td className="cel">2.1. Los ascensores no están revisados. Consultar con la empresa de mantenimiento.</td>
                                <td className="cel">Realizar un correcto mantenimiento de los ascensores. Adicionalmente es importante que las salas de máquinas de los ascensores se encuentren cerradas, que disponga de una señal de riesgos eléctrico y un cartel en las inmediaciones del cuarto de máquinas del ascensor alertando del riesgo y la prohibición de acceso a toda persona ajena al servicio.</td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>3- ¿Dispone de azotea transitable?</th>
                            </tr>
                            <tr>
                                <td className="cel">3.1. El acceso a la azotea no se realiza de forma segura</td>
                                <td className="cel">
                                    <span>Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad.</span>
                                    <br /><br />
                                    <span>Contemplar la posible necesidad de acondicionar un acceso seguro.</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="cel">3.2. El acceso a la azotea mediante escaleras no es seguro</td>
                                <td className="cel">Se recomienda instalar escaleras de servicio segura para el acceso a la azotea, o valorar con la ayuda de un profesional la instalación de un medio de acceso seguro.</td>
                            </tr>
                            <tr>
                                <td className="cel">3.3. La azotea no dispone de protección perimetral que impida la caída de personas a diferente nivel o la existente no cumple con la altura mínima.</td>
                                <td className="cel">
                                    <span>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié.</span>
                                    <br /><br />
                                    <span>Las azoteas transitables deberán disponer de protección perimetral que impida la caída de altura. En su defecto línea de vida.</span>
                                    <br /><br />
                                    <span>Si la comunidad no dispone de ninguno se recomienda contactar con empresa especializada en trabajos en altura/construcción de edificios/arquitecto que pueda recomendar la instalación más adecuada según las características del edificio.</span>
                                    <br /><br />
                                    <span>Mientras tanto, si se realizan trabajos en la azotea, nos deberíamos de asegurar que el trabajador/a que accede lo realiza en condiciones de seguridad y dispone de protección, como por ejemplo línea de vida portátil. Se recomienda también poner un cartel que impida el acceso sin presencia de algún miembro de la presidencia de la finca.</span>
                                </td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>4- ¿Disponen de extintores?</th>
                            </tr>
                            <tr>
                                <td className="cel">4.1. La comunidad de propietarios no dispone de número suficiente de extintores en la comunidad.</td>
                                <td className="cel">Colocar extintores suficientes en la comunidad de propietarios. De acuerdo con el Real Decreto 2177/1996 los extintores y medidas contra incendios serán obligatorios en edificios posteriores a 1996 o que hayan sufrido una reforma estructural del edificio posterior a dicha fecha. De acuerdo al Código Técnico de la Edificación (2006) se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.</td>
                            </tr>
                            <tr>
                                <td className="cel">4.2. En la comunidad hay extintores que no están correctamente revisados.</td>
                                <td className="cel">Revisar los extintores y medidas contra incendios. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada. Consultar con la empresa de mantenimiento.</td>
                            </tr>
                            <tr>
                                <td className="cel">4.3 En la comunidad hay extintores que no están correctamente señalizados.</td>
                                <td className="cel">
                                    <span>Señalizar los extintores. De acuerdo al Código Técnico de la Edificación (2006). La colocación de las señales es recomendable en edificios anteriores al 2006.</span>
                                    <br/><br/>
                                    <span style={{width: '100%', display: 'block', textAlign:'center'}}>
                                        <img src={extintor} alt="Extintor" style={{ width:'50px' }} />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="cel">4.4. En la comunidad hay extintores que no están colgados adecuadamente.</td>
                                <td className="cel">Colocar los extintores a la altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo. Se recomienda ir adaptando a la nueva normativa los extintores instalados con anterioridad y cuya altura reglamentaria máxima era de 1,70m.</td>
                            </tr>
                            <tr>
                                <td className="cel">4.5. En la comunidad hay extintores que no están en buen estado de conservación</td>
                                <td className="cel">Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>5- ¿Disponen de iluminación de emergencias?</th>
                            </tr>
                            <tr>
                                <td className="cel">5.1. Los recorridos de evacuación no están iluminados.</td>
                                <td className="cel">
                                    <span>Iluminar los recorridos de evacuación, de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de iluminación de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</span>
                                    <br /><br />
                                    <span style={{width: '100%', display: 'block', textAlign:'center'}}>
                                        <img src={lucesEmergencia} alt="Luces de emergencia" style={{ width:'100px' }} />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="cel">5.2. Las salidas de la finca no están iluminadas </td>
                                <td className="cel">Iluminar las salidas de emergencia, de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de iluminación de emergencia en las puertas de salida para minimizar las consecuencias en caso de emergencia o evacuación.</td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>6- ¿Disponen de señalización de emergencias?</th>
                            </tr>
                            <tr>
                                <td className="cel">6.1. Las salidas de las fincas no están señalizadas.</td>
                                <td className="cel">
                                    <span>Señalizar las salidas de emergencia de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de señalización de emergencia en las salidas de la comunidad para minimizar las consecuencias en caso de emergencia o evacuación</span>
                                    <br /><br />
                                    <span style={{width: '100%', display: 'block', textAlign:'center'}}>
                                        <img src={salida} alt="Salida" style={{ width:'100px' }} />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="cel">6.2. Los recorridos de evacuación no están señalizados.</td>
                                <td className="cel">Señalizar los recorridos de evacuación de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de señalización de evacuación para minimizar las consecuencias en caso de emergencia o evacuación.</td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>7- ¿Dispone de jardín?</th>
                            </tr>
                            <tr>
                                <td className="cel">7.2. Los productos fitosanitarios no se guardan bajo llave. </td>
                                <td className="cel">Se recomienda que estos productos estén en un lugar cerrado bajo llave.</td>
                            </tr>
                            <tr>
                                <td className="cel">7.3. No se disponen de las fichas de seguridad de los productos fitosanitarios.</td>
                                <td className="cel">Se recomienda adquirir las fichas de seguridad de los productos químicos</td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>8- ¿Dispone de piscina?</th>
                            </tr>
                            <tr>
                                <td className="cel">8.2. No se dispone de protección perimetral en la piscina.</td>
                                <td className="cel">Se recomienda colocar protección perimetral en las inmediaciones del vaso de la piscina. Consultar normativa autónoma o provincial.</td>
                            </tr>
                            <tr>
                                <td className="cel">8.3. No se dispone de aros salvavidas en la piscina</td>
                                <td className="cel">Se recomienda colocar aros salvavidas en la piscina. Consultar normativa autónoma o provincial.</td>
                            </tr>
                            <tr>
                                <th className="header2" colSpan={2}>9- ¿Dispone de parking?</th>
                            </tr>
                            <tr>
                                <td className="cel">9.1. El parking no cuenta con el número suficiente de extintores. </td>
                                <td className="cel">Colocar extintores suficientes en el parking. De acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006 se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.2. Los extintores del parking no están correctamente revisados.</td>
                                <td className="cel">Revisar los extintores del parking. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.3. Los extintores del parking no están correctamente señalizados.</td>
                                <td className="cel">
                                    <span>Señalar los extintores del parking. De acuerdo al Real Decreto 513/2017, debe estar señalizada la ubicación de los extintores.</span>
                                    <br/><br/>
                                    <span style={{width: '100%', display: 'block', textAlign:'center'}}>
                                        <img src={extintor} alt="Extintor" style={{ width:'50px' }} />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="cel">9.4. Los extintores del parking no están colgados a una altura adecuada.</td>
                                <td className="cel">Colocar los extintores a una altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo. Se recomienda ir adaptando a la nueva normativa los extintores instalados con anterioridad y cuya altura reglamentaria máxima era de 1,70m.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.5. Los extintores del parking no están en buen estado de conservación</td>
                                <td className="cel">Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.6. Los recorridos de evacuación del parking no están señalizados.</td>
                                <td className="cel">Señalizar los recorridos de evacuación del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Se recomienda la instalación de señalización de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.7. Las salidas de emergencia del parking no están señalizadas.</td>
                                <td className="cel">Señalizar las salidas de emergencia del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Se recomienda la instalación de señalización de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.8. Las direcciones de circulación del parking no están señalizadas.</td>
                                <td className="cel">Pintar las direcciones de circulación del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Solo afecta a parkings de más de 500 metros cuadrados.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.9. Las puertas de entrada y salida del parking no están señalizadas.</td>
                                <td className="cel">Señalizar las puertas de entrada y salida del parking.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.10. El parking no dispone de sistemas de extracción de humos</td>
                                <td className="cel">Dptar de sistemas de extracción de humos de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Esta medida aplicará en aquellos parkings con más de 500 metros cuadrados o de al menos 2 pisos.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.11. El parking no dispone de BIE's.</td>
                                <td className="cel">Dotar de BIE´s de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Consultar con empresa de mantenimiento.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.12. El cuadro eléctrico se encuentra abierto</td>
                                <td className="cel">El cuadro eléctrico del parking debe estar cerrado/protegido de acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión.</td>
                            </tr>
                            <tr>
                                <td className="cel">9.13. No está señalizado el cuadro eléctrico.</td>
                                <td className="cel">
                                    <span>Dotar al cuadro eléctrico del parking con el panel de "Riesgo eléctrico" en el parking. De acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión se recomienda su instalación.</span>
                                    <br/><br/>
                                    <span style={{width: '100%', display: 'block', textAlign:'center'}}>
                                        <img src={peligro} alt="Peligro" style={{ width:'50px' }} />
                                    </span>
                                </td>
                            </tr>
                        </table>
                        {/* <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>OBSERVACIONES</div> */}
                        <table style={{marginTop:30}}>
                            <tr>
                                <th className="header1">OBSERVACIONES</th>
                            </tr>
                            <tr><td className="cel2">
                            <div style={{ minHeight: 100 }}><p style={{ whiteSpace: "pre-line" }}>{this.state.hojaVisita?.comentarios}</p></div>
                            </td></tr>
                        </table>
                        {this.state.centroTrabajo?.imagenes?.length > 0 && this.state.centroTrabajo?.imagenes !== undefined ? 
                            <>
                                <div className="pagebreak"></div>
                                <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>IMAGENES</div>
                                <div style={{padding: 10, textAlign: "center"}}>
                                        {this.state.imagenes.map((url, i) => {
                                            return <img key={i} src={url} style={{width: 200}} />
                                        }) }
                                </div>
                            </>
                        : null }
                    </div>
                </tbody>
            </table>
            <div style={{
                width: '100%',
                height: 120,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 40px',
                position: "fixed",
                top: 0,
                left: 0
            }}>
                <div style={{ display: 'flex', color: '#ffffff', alignItems: 'center', justifyContent:'center' }}>
                    <img style={{ width: '90px', margin: '0 auto', marginTop: 0 }} src={LogoNF} alt="Logo NF" />
                    {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20, paddingLeft: 20, borderLeft: '2px solid #ffffff', height: 'fit-content', fontSize: 20 }}>
                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold' }}>HOJA DE VISITA</p>
                        <p style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</p>
                    </div> */}
                </div>
                {/* <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoMC} alt="Logo M&C" /> */}
            </div>
            <style jsx>{`
            div {
                font-size: 14px
            }
            table {
                border-collapse: collapse;
                width: 100%;
                table-layout: fixed;
            }
            .header1{
                color: #ffffff;
                border: 1px solid #000000;
                background-color: #33332d; 
                text-align: center; 
                margin-top: 20px; 
                margin-bottom: 0; 
                font-size: 14px; 
                padding: 4px;
            }
            .header2{
                color: #ffffff;
                border: 1px solid #000000;
                background-color: #848474; 
                text-align: center; 
                margin-top: 20px; 
                margin-bottom: 0; 
                font-size: 14px; 
                padding: 4px;
            }
            .cel, .cel1, .cel2 {
                border: 1px solid #000000;
                padding: 5px;
                line-height: 1.1;
                text-align: left
            }
            .cel1 {
                width: 30%;
                background-color: #848474;
            }
            .cel2 {
                width: 70%;
            }
            p {
                margin: 0
            }
            ul {
                padding-left: 20px
            }
            li {
                margin-top: 5px
            }
            .header-space {
                height: 120px;
            }
            @page {
                size: A4 portrait;
                margin: 0 0 30px 0;
            }
        `}</style>
        </>
    }
}

export default withRouter(HojaVisitaRender);
