import React from "react"
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ProveedorIcon from "../icons/proveedor.svg";
import TecnicoIcon from "../icons/tecnico.svg";
import ConsultorIcon from "../icons/consultor.svg";
import ComercialIcon from "../icons/comercial.svg";
import AlertaIcon from "../icons/alerta.svg";
import VerIcon from "../icons/lupa-blanca.svg";

import Inicio from "./pages/admin/Inicio";
import AAFF from "./pages/admin/AAFF";
import NuevoAAFF from "./pages/admin/NuevoAAFF";
import Comunidades from "./pages/admin/Comunidades";
import Comunidad from "./pages/admin/Comunidad";
import Proveedores from "./pages/admin/Proveedores";
import ProveedoresFincas from "./pages/admin/ProveedoresFincas";
import ProveedorFinca from "./pages/admin/ProveedorFinca";
import NuevoConsultor from "./pages/admin/NuevoConsultor";
import Consultores from "./pages/admin/Consultores";
import AAFFConsultor from "./pages/admin/AAFFConsultor";
import ProveedoresConsultor from "./pages/admin/ProveedoresConsultor";
import NuevoTecnico from "./pages/admin/NuevoTecnico";
import Tecnicos from "./pages/admin/Tecnicos";
import AAFFTecnico from "./pages/admin/AAFFTecnico";
import HojasVisita from "./pages/admin/HojasVisita";
import AsignarConsultor from "./pages/admin/AsignarConsultor";
import Proveedor from "./pages/admin/Proveedor";
import AsignarTecnico from "./pages/admin/AsignarTecnico";
import ProveedoresLista from "./pages/admin/ProveedoresLista";
import ProveedorDatos from "./pages/admin/ProveedorDatos";
import Comerciales from "./pages/admin/Comerciales";
import NuevoComercial from "./pages/admin/NuevoComercial";
import AsignarComercial from "./pages/admin/AsignarComercial";
import AAFFComercial from "./pages/admin/AAFFComercial";
import ProveedoresComercial from "./pages/admin/ProveedoresComercial";
import AlertasProveedores from "./pages/admin/alertasProveedores";
import NuevoCentroTrabajo from "./pages/admin/NuevoCentroTrabajo";
import GestionComunidades from "./pages/admin/gestionComunidades";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<AAFF />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff/nuevo" element={<NuevoAAFF />} />
      <Route path="/aaff/:nif/comunidades" element={<Comunidades />} />
      <Route path="/aaff/:nif/comunidades/:nifFinca" element={<Comunidad />} />
      <Route path="/aaff/:nif/proveedores" element={<Proveedores />} />
      <Route path="/aaff/:nifAAFF/proveedores/:nifProveedor" element={<Proveedor />} />
      <Route path="/aaff/:nifAAFF/comunidades/:nifFinca/proveedores" element={<ProveedoresFincas />} />
      <Route path="/aaff/:nifAAFF/comunidades/:nifFinca/proveedores/:nifProveedor" element={<ProveedorFinca />} />
      <Route path="/aaff/:nif/consultor" element={<AsignarConsultor />} />
      <Route path="/aaff/:nif/tecnico" element={<AsignarTecnico />} />
      <Route path="/aaff/:nif/comercial" element={<AsignarComercial />} />
      <Route path="/consultores" element={<Consultores />} />
      <Route path="/consultores/nuevo" element={<NuevoConsultor />} />
      <Route path="/consultores/:nif/aaff" element={<AAFFConsultor />} />
      <Route path="/consultores/:nifConsultor/aaff/:nifAAFF/proveedores" element={<ProveedoresConsultor />} />
      <Route path="/tecnicos" element={<Tecnicos />} />
      <Route path="/tecnicos/nuevo" element={<NuevoTecnico />} />
      <Route path="/tecnicos/:nif/aaff" element={<AAFFTecnico />} />
      <Route path="/tecnicos/:nifTecnico/aaff/:nifAAFF/hojas-visita" element={<HojasVisita />} />
      <Route path="/proveedores" element={<ProveedoresLista />} />
      <Route path="/proveedores/:nifProveedor" element={<ProveedorDatos />} />
      <Route path="/comerciales" element={<Comerciales />} />
      <Route path="/comerciales/nuevo" element={<NuevoComercial />} />
      <Route path="/comerciales/:nifComercial/aaff" element={<AAFFComercial />} />
      <Route path="/comerciales/:nifComercial/aaff/:nifAAFF/proveedores" element={<ProveedoresComercial />} />
      <Route path="/alertas" element={<AlertasProveedores />} />
      <Route path="/comunidades/:nifFinca/nuevoCentro" element={<NuevoCentroTrabajo />} />
      <Route path="/comunidades" element={<GestionComunidades />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <NavLink to="/aaff">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={Edificios} alt="Administradores de Fincas" />
            <span>Administradores de Fincas</span>

          </div>
        </div>
      </NavLink>
      <NavLink to="/consultores">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={ConsultorIcon} alt="Consultores" />
            <span>Consultores</span>

          </div>
        </div>
      </NavLink>
      <NavLink to="/tecnicos">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={TecnicoIcon} alt="Técnicos" />
            <span>Técnicos</span>

          </div>
        </div>
      </NavLink>
      <NavLink to="/proveedores">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={ProveedorIcon} alt="Proveedores" />
            <span>Proveedores</span>

          </div>
        </div>
      </NavLink>
      <NavLink to="/comerciales">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={ComercialIcon} alt="Comerciales" />
            <span>Comerciales</span>

          </div>
        </div>
      </NavLink>
      <NavLink to="/alertas">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={AlertaIcon} alt="Alertas" />
            <span>Alertas</span>

          </div>
        </div>
      </NavLink>
      {/* <Link to="/comunidades">
        <div className="menuBtn">
          <div>
            <img src={VerIcon} alt="comunidades pendientes" />
            <span>Control</span> Comunidades

          </div>
        </div>
      </Link> */}
    </>
  )
}