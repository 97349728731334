import React, { useEffect, useState } from 'react'
import { withRouter } from '../../../providers/withRouter';
import { firestore, storage } from "../../../providers/firebase";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet, Font, Svg, Link } from "@react-pdf/renderer";
import CircularProgress from '@material-ui/core/CircularProgress';
import LogoNF from "../../../images/Logo.png"
import salida from "../../../images/salida.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import extintor from "../../../images/extintor.png"
import peligro from "../../../images/peligro.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import pica from 'pica';
import Portada from "../../../images/portadaIR.png"
import RobotoRegular from "../../../fonts/Roboto-Regular.ttf"
import RobotoBold from "../../../fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../../fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../../fonts/Roboto-BoldItalic.ttf"
import { FaArrowsToDot } from "react-icons/fa6";

Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoItalic, fontWeight: 400 },
      { src: RobotoRegular, fontWeight: 400 },
      { src: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap', fontWeight: 700 },
      { src: RobotoBoldItalic, fontWeight: 800 },
    ]
});

const EvaluacionRiesgosPDF = (props) => {
    
    const [hojaVisita, setHojasVisita] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        firestore.collection("hojasVisita").doc(props.params.nifFinca).get().then(doc => {
            const hojaVisita = doc.data()
            storage.ref(hojaVisita?.img).getDownloadURL().then(img => {
                hojaVisita['imgURL'] = img
                let fechaVisita = new Date(hojaVisita?.fechaVisita.seconds * 1000)
                let fechaVisitaRenovacion = hojaVisita?.fechaVisitaRenovacion === undefined ? new Date (0) : new Date(hojaVisita?.fechaVisitaRenovacion?.seconds * 1000)
                let fechaRealizacion = hojaVisita?.fechaVisitaRenovacion === undefined || (hojaVisita?.fechaVisitaRenovacion < hojaVisita?.fechaVisita) || (Date.parse(hojaVisita?.fechaVisitaRenovacion) < Date.parse(hojaVisita?.fechaVisita)) ? new Date(hojaVisita?.fechaVisita.seconds * 1000) : new Date(hojaVisita?.fechaVisitaRenovacion?.seconds * 1000)
                hojaVisita['diffFechas'] = hojaVisita?.fechaVisitaRenovacion?.seconds < hojaVisita?.fechaVisita?.seconds
                hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                hojaVisita['fechaRealizacion'] = ("0" + fechaRealizacion.getDate()).slice(-2) + "-" + ("0" + (fechaRealizacion.getMonth() + 1)).slice(-2) + "-" + fechaRealizacion.getFullYear()
                hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                if(fechaVisitaRenovacion === undefined || hojaVisita?.diffFechas === true || fechaVisitaRenovacion.getFullYear()=== 1970){
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                    // console.log(fechaVisita.toLocaleDateString())
                }else{
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                    // console.log(fechaVisitaRenovacion.toLocaleDateString())
                }
                setHojasVisita( hojaVisita )
                setIsLoading(false)
                // comprimirImagen(hojaVisita?.imgURL)
                // fetchImage(hojaVisita?.img);
                console.log(hojaVisita)
                console.log(hojaVisita?.imgURL)
            })
        }).catch(err => console.log(err))
        /* const fetchImage = async (img) => {
            // Obtener la URL de la imagen desde Firebase Storage
            const response = await fetch('https://firebasestorage.googleapis.com/v0/b/servicios-comunidades.appspot.com/o/hojasVisita%2F66666666Q%2F16593500850674401986622524534665.jpg?alt=media&token=d3213639-d7d4-410f-8b0b-8d43c4cf5ffd');
            const arrayBuffer = await response.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);
            
            // Convertir los datos Uint8Array en una cadena base64
            const base64String = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
            const imageUrl = `data:image/jpeg;base64,${btoa(base64String)}`;
            console.log(imageUrl)
            setImageUrl(imageUrl);
          }; */
    },[])

    const comprimirImagen = (imageUrl) => {
        const picaInstance = pica();
        const imgElement = new Image();
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => picaInstance.resize(blob, imgElement, { quality: 0.7 }))
            .then(result => {
                const compressedImg = result.toDataURL();
                console.log(compressedImg)
                setImageUrl(compressedImg)
        });
	};

    const styles = StyleSheet.create({
        imgPrincipal:{
            height:'400px', 
            width:'100%', 
            marginTop:30
        },
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: '2cm',
        },
        textoNormal: {
            // fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 10,
            fontStyle: 'normal',
        },
        textoItalic: {
            // fontFamily: 'Roboto',
            fontWeight: 400
        },
        textoNegritaItalic: {
            // fontFamily: 'Roboto',
            fontWeight: 800
        },
        textoNegrita: {
            // fontFamily: 'Roboto',
            fontWeight: 700
        },
        table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 1 
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableColHeader: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            
        }, 
        tableColHeader1: { 
            width: "40%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
        }, 
        tableColHRiesgos: { 
            width: "100%",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
        }, 
        tableColH_1: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#33332d',
            color: '#ffffff'
        }, 
        tableColH_2: { 
            width: "80%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#33332d',
            color: '#ffffff'
        }, 
        tableColSH_1: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#848474',
            color: '#ffffff'
        }, 
        tableColSH_2: { 
            width: "80%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#848474',
            color: '#ffffff'
        }, 
        /* tableColHeaderSOS: { 
            width: "50%",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#ffffff',
            color: '#000000',
        }, 
        tableColHeaderNM: { 
            width: "100%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#ffffff',
            color: '#000000',
        },  */
        tableCol: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableCol1: { 
            width: "40%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
        }, 
        tableColRiesgos: { 
            width: "100%",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
        }, 
        tableColCont_1: { 
            width: "20%",
            borderStyle: "solid", 
            borderWidth: 0, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            color:'red',
        }, 
        tableColCont_2: { 
            width: "80%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
        }, 
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 8 
        },
        pdfHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            backgroundColor: '#ffffff',
            /* margin:'2cm', */
        },
        pdfHeaderImg:{
            width:'60px', 
            height:'60px',
            marginBottom:'30px',
        },
        tablePage: {
            width: '100%',
        },
        headerSpace: {
            height: '90px',
        },
        div: {
            fontSize: '10px'
        },
        h1: {
            /* marginLeft:'2cm',
            marginRight:'2cm', 
            marginTop: '50px',*/
            marginBottom: '30px',
            fontSize: '14px',
            fontWeight: '600',
        },
         h2: {
            fontSize: '11px',
            fontWeight: '600',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            marginTop: '10px',
            marginBottom: '10px',
        },
        h3: {
            fontSize: '9px',
            width: '100%',
            padding: '5px 0px',
            textTransform: 'uppercase',
            borderBottom: '1px solid #848474',
            marginTop: '25px',
            marginBottom: '15px',
            color: "#848474",
        },
        h4: {
            color: '#848474',
            marginBottom: '10px',
            marginTop: '20px',
            marginLeft: '10px',
            fontSize: '8px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        h5: {
            color: '#848474',
            fontSize: '8px',
            marginLeft: '20px',
            marginTop: '15px',
            marginBottom: '5px',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        /* textBox: {
            border: '2px solid #e6e6e6',
            padding: '15px',
            marginTop: '15px',
        }, */
        tableRiesgos: {
            borderCollapse: 'collapse',
            width: '100%',
            tableLayout: 'fixed',
            marginBottom: '24px',
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            backgroundColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        },
        /* simpleTableTh: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        }, */
        /* simpleTableTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        },
        tableRiesgosTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        },
        tableRiesgosTh: {
            backgroundColor: '#375491',
            color: '#ffffff',
            fontSize: '12px',
            fontWeight: 'bold',
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        }, */
        simpleTable: {
            borderCollapse: 'collapse',
            width: '80%',
            margin: '0 10% 24px 10%',
        },
        simpleTableTh: {
            textTransform: 'uppercase',
        },
        /* subul: {
            lineHeight: '1.8',
            fontSize:'8px',
            flexDirection: "column", 
            width: 400,
            marginLeft: '8px',
        }, */
        ul: {
            // lineHeight: '1.8',
            // fontSize:'7px',
            flexDirection: "column", 
            width: '90%',
        },
        ol: {
            lineHeight: '1.8',
            flexDirection: "row", 
            marginBottom: 4,
            fontSize: '9px',
        },
        p: {
            lineHeight: '1.8',
            textAlign: 'justify',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            fontSize: '7.5px',
        },
        subp: {
            lineHeight: '1.8',
            textAlign: 'justify',
            marginLeft: '8px',
            fontSize: '8px',
        },
        br: {
            display: 'block',
            margin: '10px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        /* pageTitle: {
            textAlign: 'center',
            textDecoration: 'underline',
            fontWeight: 'bold',
            fontSize: '12px',
            marginBottom: '20px'
        }, */
        page: {
            size: 'A4 portrait',
            margin: '2cm',
        },
        pagebreak: {
            clear: 'both',
            pageBreakAfter: 'always',
          }
      });
    return ( 
        <>
            {isLoading ? <CircularProgress style={{color:'#848474', position: 'absolute', top: '50%', left: '50%'}} /> :
                <PDFViewer style={{ width:"100%", height:"100vh"}}>
                    <Document size="A4" margin="2cm">
                        <Page>
                            <View>
                                <Image src={Portada} alt="Portada" style={{height:"100vh"}}/>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            {/* <View style={styles.pdfHeader} fixed>
                                <Image style={styles.pdfHeaderImg} src={LogoNF} alt="Logo NF" />
                            </View>
                            <View>
                                <View>
                                    <Text style={styles.h1}>IDENTIFICACIÓN DE RIESGOS</Text>
                                    <View ><Text style={styles.h2}>Comunidad de propietarios: {hojaVisita?.comunidad.razonSocial}</Text> </View>
                                    <View><Text style={styles.h2}>Centro de Trabajo: {hojaVisita?.comunidad.direccion}</Text> </View>
                                    <View><Text style={styles.h2}>Fecha: {hojaVisita?.fechaMostrar }</Text> </View>
                                    <Image style={styles.imgPrincipal} src={hojaVisita?.imgURL} />
                                </View>
                            </View> */}
                            <View style={{marginTop: 0, marginHorizontal: '0cm'}} fixed>
                                <View >
                                    <Image src={LogoNF} style={{marginVertical: 0, marginHorizontal:'auto', marginTop: 0, width:'45px'}} />
                                </View>
                            </View>
                             <View>
                                <View>
                                    <Text style={styles.h3}>Introducción</Text>
                                    <Text style={styles.p}>
                                        De conformidad con la legislación vigente en prevención de Riesgos Laborales (Ley 31/1995) la <Text style={styles.textoNegrita}>{hojaVisita?.comunidad.razonSocial}</Text> al considerarse un centro de trabajo, debe realizar una identificación de riesgos para cumplir con el art. 24 de Coordinación de Actividades Empresariales (CAE).<br />
                                    </Text>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.p}>
                                        La coordinación de actividades empresariales, dada la relevancia en la normativa de riesgos laborales, se desarrolló en el Real Decreto 171/2004 y definió un centro de trabajo como “cualquier área, edificada o no, en la que los trabajadores deban permanecer o a la que deban acceder por razón de su trabajo”.< br />
                                    </Text>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.p}>
                                        Una comunidad de propietarios es un edificio, parking o zonas comunes en las que trabajadores de empresas externas acuden de forma habitual a realizar labores de mantenimiento. La concurrencia en la comunidad de estas empresas (o de sus empleados) es la base sobre la que pivota la normativa, y por tanto el germen de la obligación de realizar esta comunicación bidireccional entre centro de trabajo y empresas proveedoras.
                                    </Text>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.p}>
                                        Así pues, en el art. 4 del RD 171/2004 se indica lo siguiente:
                                    </Text>
                                    <Text style={styles.br}></Text>
                                    <View style={styles.ul}>
                                        <View style={styles.ol}>
                                            <Text style={{ marginHorizontal: 8 }}>1.</Text>
                                            <Text>
                                                Cuando en un mismo centro de trabajo desarrollen actividades trabajadores de dos o más empresas, éstas deberán cooperar en la aplicación de la normativa de prevención de riesgos laborales en la forma que se establece en este capítulo.
                                            </Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={{ marginHorizontal: 8 }}> </Text>
                                            <Text>
                                                El deber de cooperación será de aplicación a todas las empresas y trabajadores autónomos concurrentes en el centro de trabajo, existan o no relaciones jurídicas entre ellos.
                                            </Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={{ marginHorizontal: 8 }}>2.</Text>
                                            <Text>Las empresas a que se refiere el apartado 1 deberán informarse recíprocamente sobre los riesgos específicos de las actividades que desarrollen en el centro de trabajo que puedan afectar a los trabajadores de las otras empresas concurrentes en el centro, en particular sobre aquellos que puedan verse agravados o modificados por circunstancias derivadas de la concurrencia de actividades.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={{ marginHorizontal: 8 }}> </Text>
                                            <Text>La información deberá ser suficiente y habrá de proporcionarse antes del inicio de las actividades, cuando se produzca un cambio en las actividades concurrentes que sea relevante a efectos preventivos y cuando se haya producido una situación de emergencia.</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.p}>
                                        Por tanto, y dado que el centro de trabajo debe cooperar e informar a los agentes involucrados en cualquier tarea de mantenimiento de la comunidad, en este informe se detallarán los riesgos del centro de trabajo, para que en coordinación con los servicios de prevención ajenos (o modalidad preventiva escogida), exista la debida y suficiente colaboración e información.
                                    </Text>
                                    
                                    <Text style={styles.h3}>DATOS GENERALES DEL CENTRO DE TRABAJO</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>COMUNIDAD DE PROPIETARIOS:</Text> {hojaVisita?.comunidad.razonSocial}</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>C.I.F.:</Text> {hojaVisita?.comunidad.nif}</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>DIRECCIÓN:</Text> {hojaVisita?.comunidad.direccion}</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>CÓDIGO POSTAL:</Text> {hojaVisita?.comunidad.codigoPostal}</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>LOCALIDAD:</Text> {hojaVisita?.comunidad.localidad}</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>PROVINCIA:</Text> {hojaVisita?.comunidad.provincia}</Text>
                                    <Text style={styles.p}><Text style={{textDecoration:'underline'}}>FECHA DE VISITA:</Text> {hojaVisita?.fechaRealizacion}</Text>
                                    <Text style={styles.br}></Text>
                                    <Text style={styles.p}>
                                        El informe se ajusta al centro de trabajo, no siendo un modelo de organización preventiva dado que la comunidad de propietarios no dispone de trabajadores. En caso de disponer de empleados el centro de trabajo tendrá que adherirse a una modalidad preventiva recogida en la normativa actual.
                                    </Text>
                                </View>
                            </View>
                            <View break>
                                <Text style={styles.h3}>OBJETO</Text>
                                <Text style={styles.p}>
                                    La presente identificación de riesgos tiene por objeto identificar los riesgos o deficiencias en materia de seguridad y salud existentes en la comunidad de propietarios como centro de trabajo, para permitir una adecuada planificación de las actuaciones preventivas e iniciar un proceso de mejora continuada de las condiciones de trabajo. 
                                </Text>
                                <Text style={[styles.p, {textDecoration:'underline'}]}>
                                    Es importante tener en cuenta que:
                                </Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Este documento se ha realizado a partir de los datos tomados por nuestro técnico o técnica, en base a las condiciones del centro de trabajo y a los trabajos realizados el día en que se produce la visita.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>La valoración de los riesgos identificados se realiza en la misma fecha que se indica en el apartado de datos generales</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Será la comunidad de propietarios última instancia quién tiene que garantizar la seguridad y salud de las personas trabajadoras en sus instalaciones como objeto del presente informe y dentro de la adecuada coordinación de actividades empresariales (art. 24 de la Ley de Prevención de Riesgos Laborales). </Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Cabe subrayar que los datos incluidos en el informe de evaluación inicial sirven únicamente como punto de partida en lo que se refiere a una futura planificación por parte de la comunidad de la acción preventiva, tanto en lo que respecta a las posibles medidas preventivas a llevar a cabo, donde se tendrá en cuenta los riesgos identificados y evaluados en este informe, como la realización de evaluaciones específicas.</Text>
                                    </View>
                                </View>
                                <Text style={styles.h3}>COORDINACIÓN DE ACTIVIDADES EMPRESARIALES (CAE)</Text>
                                <Text style={styles.p}>
                                    Según el R.D. 171/04 que desarrolla el artículo 24 de la Ley de Prevención de Riesgos Laborales la comunidad de propietarios tiene una serie de responsabilidades sobre las empresas subcontratadas. 
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    El artículo 24 establece que cuando en un mismo centro de trabajo desarrollen actividades personas trabajadoras de dos o más empresas, éstas deberán cooperar en la aplicación de la normativa sobre prevención de riesgos laborales. A tal fin, establecerán los medios de coordinación que sean necesarios en cuanto a la protección y prevención de riesgos laborales y la información sobre los mismos a sus respectivos trabajadores y trabajadoras, en los términos previstos en el apartado 1 del artículo 18 de esta Ley.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    La comunidad de propietarios, como titular del centro de trabajo, adoptará las medidas necesarias para que aquellos otros empresarios o empresarias que desarrollen actividades en su centro de trabajo reciban la información y las instrucciones adecuadas, en relación con los riesgos existentes en el centro de trabajo y con las medidas de protección y prevención correspondientes, así como sobre las medidas de emergencia a aplicar, para su traslado a sus respectivas personas trabajadoras.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Las empresas que contraten o subcontraten con otras la realización de obras o servicios correspondientes a la propia actividad de aquéllas y que se desarrollen en sus propios centros de trabajo deberán vigilar el cumplimiento por dichos contratistas y subcontratistas de la normativa de prevención de riesgos laborales.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    El incumplimiento por las o los empresarios de sus obligaciones en materia de prevención de riesgos laborales dará lugar a responsabilidades administrativas, así como, en su caso, a responsabilidades penales y a las civiles por los daños y perjuicios que puedan derivarse de dicho incumplimiento. (Artículo 42).
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    La comunidad de propietarios responderá solidariamente con las empresas contratistas y subcontratistas a que se refiere el apartado 3 del artículo 24 de la LPRL, durante el período de la contrata, de las obligaciones impuestas por esta Ley en relación con las personas trabajadoras que aquéllos ocupen en los centros de trabajo de la empresa principal, siempre que la infracción se haya producido en el centro de trabajo de dicha comunidad de propietarios.
                                </Text>
                                <Text style={styles.h3}>PRINCIPALES RIESGOS DE LAS COMUNIDADES DE PROPIETARIOS</Text>
                                <Text style={styles.p}>
                                A continuación, se analizan riesgos generales que pueden incidir en las actividades de mantenimiento desarrolladas por las empresas contratadas.
                                </Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColH_1}> 
                                            <Text style={styles.tableCell}>Elemento común</Text> 
                                        </View> 
                                        <View style={styles.tableColH_2}> 
                                            <Text style={styles.tableCell}>Origen</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColSH_1}> 
                                            <Text style={styles.tableCell}>Escaleras fijas</Text> 
                                        </View> 
                                        <View style={styles.tableColSH_2}> 
                                            <Text style={styles.tableCell}>Son los elementos fijos de la estructura que comunican los pisos de cada planta con la salida del edificio y/o el parking subterráneo</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={[styles.tableCell, {textDecoration:'underline'}]}>Medidas informativas</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Se deberá realizar un mantenimiento periódico de las instalaciones y el suelo de las escaleras fijas de la comunidad de propietarios</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Las operaciones de limpieza que se realicen con métodos húmedos y que puedan hacer que los suelos resulten resbaladizos se realizarán preferentemente en horario con menos afluencia de personas y se evitará el tránsito sobre el suelo mientras esté mojado. Cuando se realice la limpieza por métodos húmedos, así como cuando se produzca un derrame accidental de líquidos, se deberá secar lo más pronto posible y/o señalizar (con carteles informativos de advertencia por suelo mojado) y/o alertar sobre dicha circunstancia</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Se deberá llevar un correcto mantenimiento periódico del suelo del edificio en general.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>En la medida de lo posible se deberán sustituir los accesos de un escalón por rampas, para cumplir con lo establecido en el Real Decreto 486/1997, de 14 de abril, por el que se establecen las disposiciones mínimas de seguridad y salud en los lugares de trabajo. Real Decreto 486/1997 - Lugares de trabajo. Ley 31/1995 - </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>La ausencia de una adecuada iluminación en el lugar de trabajo puede favorecer caídas, golpes, etc. Por ello, se deben mantener todas las luminarias en perfecto estado, limpias y revisadas. Sustituir con rapidez cualquier lámpara fundida</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColH_1}> 
                                            <Text style={styles.tableCell}>Elemento común</Text> 
                                        </View> 
                                        <View style={styles.tableColH_2}> 
                                            <Text style={styles.tableCell}>Origen</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColSH_1}> 
                                            <Text style={styles.tableCell}>Extintores</Text> 
                                        </View> 
                                        <View style={styles.tableColSH_2}> 
                                            <Text style={styles.tableCell}>Obligación legal establecida por el Real Decreto 513/2017, de 22 de mayo, por el que se aprueba el Reglamento de Instalaciones de Protección Contra incendios, Anexo 2- Mantenimiento mínimo de las instalaciones de protección contra incendios. En referencia al mantenimiento de los extintores de incendio.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={[styles.tableCell, {textDecoration:'underline'}]}>Medidas informativas</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Los extintores serán obligatorios en comunidades construidas a partir de 1996 o en aquellas que hayan sufrido modificaciones estructurales.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Los extintores deberán ser revisados por una empresa autorizada, en los periodos que establece la normativa vigente; revisión anual, y retimbrado cada 5 años. Real Decreto 513/2017 - Instalaciones de protección contra incendios.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>La empresa deberá establecer un programa de mantenimiento trimestral en el que se realice una revisión ocular de los medios de lucha contra incendios.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Esta revisión verificará: que los extintores están en su lugar asignado y que no presentan muestras aparentes de daños; que son adecuados conforme al riesgo a proteger; que no tienen el acceso obstruido, son visibles o están señalizados y tienen sus instrucciones de manejo en la parte delantera; que las instrucciones de manejo son legibles; que el indicador de presión se encuentra en la zona de operación; que las partes metálicas (boquillas, válvula, manguera) están en buen estado; que no faltan ni están rotos los precintos o los tapones indicadores de uso; que no han sido descargados total o parcialmente. También se comprobará la señalización de los extintores.</Text> 
                                        </View> 
                                    </View>
                                    
                                </View>
                            </View>
                            <View break>    
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColH_1}> 
                                            <Text style={styles.tableCell}>Elemento común</Text> 
                                        </View> 
                                        <View style={styles.tableColH_2}> 
                                            <Text style={styles.tableCell}>Origen</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColSH_1}> 
                                            <Text style={styles.tableCell}>Señales de recorridos de evacuación</Text> 
                                        </View> 
                                        <View style={styles.tableColSH_2}> 
                                            <Text style={styles.tableCell}>Obligación establecida por el anexo II del RD 486/1997, de 14 de abril, por el que se establecen las disposiciones mínimas de seguridad y salud en los lugares de trabajo</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={[styles.tableCell, {textDecoration:'underline'}]}>Medidas informativas</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Las señales de iluminación y recorridos de evacuación serán obligatorias en edificios posteriores a 2006, según CTE. Esta norma no tiene carácter retroactivo.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Se deberán señalizar los recorridos de evacuación. La señalización para la localización e identificación de las vías y salidas de evacuación se realizará mediante señales en forma de panel de las indicadas en el apartado 3.5. del Anexo III del R.D 485/1997: Forma rectangular o cuadrada. Pictograma blanco.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>sobre fondo verde (el verde deberá cubrir como mínimo el 50% de la superficie de la señal). Real Decreto 485/1997 - Señalización.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Las señales deben ser visibles incluso en caso de fallo en el suministro al alumbrado normal. Deben cumplir lo establecido en las normas UNE 23035- 1:2003, UNE 23035-2:2003 y UNE 23035- 4:2003 y su mantenimiento se realizará conforme a lo establecido en la norma UNE 23035- 3:2003.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColH_1}> 
                                            <Text style={styles.tableCell}>Elemento común</Text> 
                                        </View> 
                                        <View style={styles.tableColH_2}> 
                                            <Text style={styles.tableCell}>Origen</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColSH_1}> 
                                            <Text style={styles.tableCell}>Cuadros eléctricos</Text> 
                                        </View> 
                                        <View style={styles.tableColSH_2}> 
                                            <Text style={styles.tableCell}>Posibilidad de sobrecarga de la instalación eléctrica por conexión de varios aparatos a la misma toma de corriente.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={[styles.tableCell, {textDecoration:'underline'}]}>Medidas informativas</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>El titular de las instalaciones eléctricas debe mantenerlas en buen estado de funcionamiento, utilizarlas teniendo en cuenta sus características y capacidades y debe abstenerse de modificarlas, ya que esto último es cometido exclusivo de la empresa instaladora. El titular de las instalaciones deberá asegurarse de que determinados elementos y dispositivos de las instalaciones eléctricas son revisados o inspeccionados periódicamente para garantizar su funcionamiento correcto y seguro.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Se deberá comprobar periódicamente el correcto funcionamiento del interruptor diferencial del cuadro eléctrico, dicha operación la puede realizar un trabajador usuario de la instalación eléctrica siempre que no implique la apertura del cuadro eléctrico en el que hubiera elementos accesibles en tensión. Los elementos de corte tienen una vida de funcionamiento limitada y que conviene cambiar al pasar cierto tiempo (consultar características técnicas de los mismos).</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>No se sobrecargará la instalación eléctrica utilizando ladrones, se instalarán diferentes tomas de conexión. Los receptores eléctricos deberán estar protegidos contra cortocircuitos y contra sobrecargas en todas sus fases.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Comprobar periódicamente la instalación eléctrica (mantenimiento) por una empresa debidamente autorizada por el Ministerio de Industria y energía y la Consejería de Industria, de forma que se cumpla lo establecido en el Reglamento electrotécnico de Baja Tensión.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Queda totalmente prohibida la manipulación interna de los cuadros eléctricos. Sólo podrá realizarla personal debidamente cualificado y autorizado para ello.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>De acuerdo a la ITC-BT-17 del Reglamento electrotécnico de baja tensión aprobado por Real Decreto 842/2002, de 2 de agosto, el cuadro eléctrico existente en la planta baja así como su caja de interruptores magnetotérmicos estarán provistos de una tapa de protección adecuada a fin de evitar contactos accidentales. Se deberán tomar las precauciones necesarias para que los dispositivos de mando y protección no sean accesibles al público.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColH_1}> 
                                            <Text style={styles.tableCell}>Elemento común</Text> 
                                        </View> 
                                        <View style={styles.tableColH_2}> 
                                            <Text style={styles.tableCell}>Origen</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColSH_1}> 
                                            <Text style={styles.tableCell}>Azotea transitable</Text> 
                                        </View> 
                                        <View style={styles.tableColSH_2}> 
                                            <Text style={styles.tableCell}>Posibilidad de caída a distinto nivel en los trabajos de mantenimiento que impliquen transitar por la azotea.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={[styles.tableCell, {textDecoration:'underline'}]}>Medidas informativas</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Se evitará trabajar en los días de fueres lluvias, temporal de viento o nieve.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>Se recomienda que las azoteas transitables dispongan de protección perimetral para evitar caídas desde la misma.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColCont_1}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColCont_2}> 
                                            <Text style={styles.tableCell}>En caso de que la azotea no disponga de protección perimetral es importante valorar la instalación de anclajes para líneas de vida o que los proveedores de mantenimiento lleven una portátil consigo. Se desaconseja el tránsito de vecinos por la azotea que no disponga de protección perimetral y estar la misma cerrada con llave.</Text> 
                                        </View> 
                                    </View>
                                </View>
                            </View>
                            <View break>   
                                <Text style={styles.h3}>IDENTIFICACIÓN DE RIESGOS Y MEDIDAS DE CORRRECCIÓN</Text>
                                <Text style={styles.p}>De conformidad con la información recogida en los apartados anteriores, {hojaVisita?.comunidad.razonSocial} identifica los distintos riesgos sobre la seguridad y salud de los trabajadores que desarrollan sus tareas en las instalaciones.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>El objetivo de la identificación de riesgos es señalar las situaciones que pueden condicionar las tareas de las empresas y trabajadores que acceden al centro de trabajo y así valorar la conveniencia de adoptar medidas y planificar correctamente las tareas de mantenimiento a realizar.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>El informe está realizado por personal cualificado en prevención de riesgos laborales, siendo en todo caso técnicos intermedios o superiores habilitados para realizar estas identificaciones de riesgos y posteriores valoraciones de las medidas correctoras.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.h4}>A)	MÉTODO PARA LA IDENTIFICACIÓN DE RIESGOS</Text>
                                <Text style={styles.p}>De acuerdo con las características de las instalaciones de {hojaVisita?.comunidad.razonSocial} y la definición de las tareas que en ella se han de llevar a cabo, se identifican los riesgos existentes, que deben ser además analizados con el objetivo de poder determinar qué circunstancias nos llevan a considerar que en esa zona común existe un riesgo.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>En el desarrollo de esta actividad, los técnicos en prevención de riesgos tienen en cuenta las instalaciones de la comunidad de propietarios, vinculados a las zonas comunes a las que acceden los proveedores de mantenimiento.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>A continuación, se detallan los riesgos identificados en {hojaVisita?.comunidad.razonSocial}.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.h5}>• ESTADO GENERAL DEL EDIFICIO</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Hay riesgo de desprendimiento de baldosas o elementos. Lugar de la incidencia.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Hay cristales rotos o en mal estado de puertas/ventanas. Lugar de la incidencia:</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Hay cables eléctricos sueltos. Lugar de la incidencia: </Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Las cajas de registro se encuentran abiertas. </Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Hay riesgo de deslizamiento en las rampas de acceso. </Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El cuadro eléctrico se encuentra abierto.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El cuadro eléctrico no se encuentra señalizado. </Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• ASCENSORES</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los ascensores no están revisados. Consultar con la empresa de mantenimiento.</Text> 
                                        </View>  
                                    </View>
                                </View>
                            </View>
                            <View break> 
                                <Text style={styles.h5}>• AZOTEA TRANSITABLE</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El acceso a la azotea no se realiza de forma segura.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El acceso a la azotea mediante escaleras no es seguro.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>La azotea no dispone de protección perimetral que impida la caída de personas a diferente nivel o la existente no cumple con la altura mínima.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• EXTINTORES DEL EDIFICIO</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>La comunidad de propietarios no dispone de número suficiente de extintores en la comunidad.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>En la comunidad hay extintores que no están correctamente revisados.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>En la comunidad hay extintores que no están correctamente señalizados.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>En la comunidad hay extintores que no están colgados adecuadamente.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>En la comunidad hay extintores que no están en buen estado de conservación.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• ILUMINACIÓN DE EMERGENCIA</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>La comunidad de propietarios no dispone de número suficiente de extintores en la comunidad.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>En la comunidad hay extintores que no están correctamente revisados.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• SEÑALIZACIÓN DE EMERGENCIA</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Las salidas de las fincas no están señalizadas.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los recorridos de evacuación no están señalizados.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• JARDIN</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los productos fitosanitarios no se guardan bajo llave.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>No se disponen de las fichas de seguridad de los productos fitosanitarios.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• PISCINA</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>No se dispone de protección perimetral en la piscina.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>No se dispone de aros salvavidas en la piscina.</Text> 
                                        </View>  
                                    </View>
                                </View>
                            </View>
                            <View break> 
                                <Text style={styles.h5}>• PARKING</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>RIESGO IDENTIFICADO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El parking no cuenta con el número suficiente de extintores.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los extintores del parking no están correctamente revisados.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los extintores del parking no están correctamente señalizados.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los extintores del parking no están colgados a una altura adecuada.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los extintores del parking no están en buen estado de conservación.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Los recorridos de evacuación del parking no están señalizados.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Las salidas de emergencia del parking no están señalizadas.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Las direcciones de circulación del parking no están señalizadas.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Las puertas de entrada y salida del parking no están señalizadas.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El parking no dispone de sistemas automáticos de extinción de incendios (rociadores).</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El parking no dispone de sistemas de extracción de humos.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El parking no dispone de BIE's.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El parking no dispone de detectores de humo.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El cuadro eléctrico se encuentra abierto.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>No está señalizado el cuadro eléctrico.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h4}>B) MÉTODO PARA ESTABLECER LAS MEDIDAS CORRECTORAS</Text>
                                <Text style={styles.p}>Las medidas correctoras que se incorporan tratar de evitar o minorar la probabilidad de que acontezcan los riesgos identificados, no siendo obligatorios en la gran mayoría de los casos, pero sí altamente recomendables.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>Debemos en este punto aclarar que los riesgos generalmente están sujetos a normativas condicionadas por el año de construcción del edificio, como por ejemplo en lo relacionado a la señalética de recorridos de evacuación, señalización de cuadros eléctricos, instalación de medidas contra incendios, etc.</Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>El trabajo es una consecuencia del análisis de situaciones de riesgo que pueden convertir una situación de emergencia (por ejemplo, un incendio) en un riesgo para la salud de los trabajadores que trabajan en la comunidad de propietarios.</Text>
                                <Text style={styles.h5}>• ESTADO GENERAL DEL EDIFICIO</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Reparar los desperfectos encontrados en el suelo.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Sustituir los cristales afectados, por otros en correcto estado.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. En especial las que crucen zonas de paso.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>
                                                <Text>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.).</Text>
                                                <Text>Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico.</Text>
                                            </Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda la instalación de tiras antideslizantes o realizar tratamiento del pavimento.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</Text> 
                                        </View>  
                                    </View>
                                </View>
                            </View>
                            <View break> 
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda señalizar el cuadro eléctrico alertando del riesgo que puede suponer su manipulación. La señalización del cuadro eléctrico es obligatoria de acuerdo al Código Técnico de la Edificación en edificios posteriores al 2006.</Text> 
                                            <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={peligro} alt="Peligro" />
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• ASCENSORES</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Realizar un correcto mantenimiento de los ascensores. Adicionalmente es importante que las salas de máquinas de los ascensores se encuentren cerradas, que disponga de una señal de riesgos eléctrico y un cartel en las inmediaciones del cuarto de máquinas del ascensor alertando del riesgo y la prohibición de acceso a toda persona ajena al servicio.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• AZOTEA TRANSITABLE</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad. Contemplar la posible necesidad de acondicionar un acceso seguro.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda instalar escaleras de servicio segura para el acceso a la azotea, o valorar con la ayuda de un profesional la instalación de un medio de acceso seguro.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>
                                                <Text>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié. Las azoteas transitables deberán disponer de protección perimetral que impida la caída de altura. En su defecto línea de vida. </Text>    
                                                <Text>Si la comunidad no dispone de ninguno se recomienda contactar con empresa especializada en trabajos en altura/construcción de edificios/arquitecto que pueda recomendar la instalación más adecuada según las características del edificio.</Text>    
                                                <Text>Mientras tanto, si se realizan trabajos en la azotea, nos deberíamos de asegurar que el trabajador/a que accede lo realiza en condiciones de seguridad y dispone de protección, como por ejemplo línea de vida portátil. Se recomienda también poner un cartel que impida el acceso sin presencia de algún miembro de la presidencia de la finca.</Text>    
                                            </Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• EXTINTORES DEL EDIFICIO</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Colocar extintores suficientes en la comunidad de propietarios. De acuerdo con el Real Decreto 2177/1996 los extintores y medidas contra incendios serán obligatorios en edificios posteriores a 1996 o que hayan sufrido una reforma estructural del edificio posterior a dicha fecha. De acuerdo al Código Técnico de la Edificación (2006) se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Revisar los extintores y medidas contra incendios. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada. Consultar con la empresa de mantenimiento.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>
                                                <Text>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié. Las azoteas transitables deberán disponer de protección perimetral que impida la caída de altura. En su defecto línea de vida. </Text>    
                                                <Text>Si la comunidad no dispone de ninguno se recomienda contactar con empresa especializada en trabajos en altura/construcción de edificios/arquitecto que pueda recomendar la instalación más adecuada según las características del edificio.</Text>    
                                                <Text>Mientras tanto, si se realizan trabajos en la azotea, nos deberíamos de asegurar que el trabajador/a que accede lo realiza en condiciones de seguridad y dispone de protección, como por ejemplo línea de vida portátil. Se recomienda también poner un cartel que impida el acceso sin presencia de algún miembro de la presidencia de la finca.</Text>    
                                            </Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Señalizar los extintores. De acuerdo al Código Técnico de la Edificación (2006). La colocación de las señales es recomendable en edificios anteriores al 2006.</Text> 
                                            <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={extintor} alt="Extintor" />
                                        </View>  
                                    </View>
                                </View>
                            </View>
                            <View break> 
                                <View style={styles.table}>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Colocar los extintores a la altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo. Se recomienda ir adaptando a la nueva normativa los extintores instalados con anterioridad.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• ILUMINACIÓN DE EMERGENCIA</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Iluminar los recorridos de evacuación, de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de iluminación de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</Text> 
                                            <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={lucesEmergencia} alt="Luces de emergencia" />
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Iluminar las salidas de emergencia, de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de iluminación de emergencia en las puertas de salida para minimizar las consecuencias en caso de emergencia o evacuación.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• SEÑALIZACIÓN DE EMERGENCIA</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}> Señalizar las salidas de emergencia de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de señalización de emergencia en las salidas de la comunidad para minimizar las consecuencias en caso de emergencia o evacuación.</Text> 
                                            <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={salida} alt="Salida" />
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Señalizar los recorridos de evacuación de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de señalización de evacuación para minimizar las consecuencias en caso de emergencia o evacuación.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• JARDÍN</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda que estos productos estén en un lugar cerrado bajo llave.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda adquirir las fichas de seguridad de los productos químicos.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• PISCINA</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda colocar protección perimetral en las inmediaciones del vaso de la piscina. Consultar normativa autónoma o provincial.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Se recomienda colocar aros salvavidas en la piscina. Consultar normativa autónoma o provincial.</Text> 
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h5}>• PARKING</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHRiesgos}> 
                                            <Text style={styles.tableCell}>MEDIDAS CORRECTORAS</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Colocar extintores suficientes en el parking. De acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006 se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Revisar los extintores del parking. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada.</Text> 
                                        </View>  
                                    </View>
                                </View>
                            </View>
                            <View break> 
                                <View style={styles.table}>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Señalar los extintores del parking. De acuerdo al Real Decreto 513/2017, debe estar señalizada la ubicación de los extintores.</Text> 
                                            <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={extintor} alt="Extintor" />
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Colocar los extintores a una altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo. Se recomienda ir adaptando a la nueva normativa los extintores instalados con anterioridad.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Señalizar los recorridos de evacuación del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Se recomienda la instalación de señalización de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Señalizar las salidas de emergencia del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Se recomienda la instalación de señalización de emergencia para minimizar las consecuencias en caso de emergencia o evacuación</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Pintar las direcciones de circulación del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Señalizar las puertas de entrada y salida del parking.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Dotar de sistemas automáticos de extinción de incendios en el parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Esta medida aplicará en aquellos parkings con más de 500 metros cuadrados o de al menos 2 pisos.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Dotar de sistemas de extracción de humos de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Esta medida aplicará en aquellos parkings con más de 500 metros cuadrados o de al menos 2 pisos.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Dotar de BIE´s de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Consultar con empresa de mantenimiento.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>Dotar de detectores de humos de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Consultar con empresa de mantenimiento.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El cuadro eléctrico del parking debe estar cerrado/protegido de acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión.</Text> 
                                        </View>  
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColRiesgos}> 
                                            <Text style={styles.tableCell}>El cuadro eléctrico del parking debe estar cerrado/protegido de acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión.</Text> 
                                            <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={peligro} alt="Peligro" />
                                        </View>  
                                    </View>
                                </View>
                                <Text style={styles.h4}>BIBLIOGRAFÍA</Text>
                                <Text style={styles.br}></Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Real Decreto 314/2006, de 17 de marzo, por el que se aprueba el Código Técnico de la Edificación.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Real Decreto 2177/1996, de 4 de octubre, por el que se aprueba la norma básica de la edificación «NBE-CPI/96: Condiciones de protección contra incendios de los edificios».</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Real Decreto-ley 1/1998, de 27 de febrero, sobre infraestructuras comunes en los edificios para el acceso a los servicios de telecomunicación.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Reglamento Electrotécnico para Baja Tensión (REBT) y sus Instrucciones Técnicas Complementarias (ITC) publicados en el Real Decreto 842/2002 de 2 de agosto.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text>Real Decreto 39/1997, de 17 de enero, por el que se aprueba el Reglamento de los Servicios de Prevención.</Text>
                                    </View>
                                </View>
                                
                            </View> 
                            
                        </Page>
                    </Document>
                </PDFViewer>
            }
            <style jsx>{`
                .imgPrincipal{
                    background-image: url(${hojaVisita?.imgURL});
                    width:100%;
                    height:40px;
                }
        `}</style>
        </>
     );
}
 
export default withRouter(EvaluacionRiesgosPDF);