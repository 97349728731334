import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Editar from "../../../icons/editar.svg"
import Documento from "../../../icons/document.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { withRouter } from "../../../providers/withRouter";
import { abrirArchivo } from "../../../providers/documentos";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from "react-toastify";
import { Collapse } from "@material-ui/core";
import NoVisitado from "../../../components/noVisitadoComunidad";
import { IoDocumentTextOutline } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { PiPencilLine } from "react-icons/pi";

class Comunidades extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      fincasPendientes: [],
      fincasVisitadas: [],
      fincasRenovadasPendientes: [],
      fincasRenovadasVisitadas: [],
      aaff: {},
      tecnico: {},
      comercial: {},
      consultor: {},
      tab: 0,
      isLoading: true,
      mensaje: '',
      observaciones:'',
      expandir: true,
      hojasVisita: [],
      centrosTrabajo: [],
      tipoOrden: 'asc',
      tipoOrdenCP: 'asc'
    }
  }
  componentDidMount() {
    let tecnico = {}
    let consultor = {}
    let comercial = {}
    let promises = []
    let fincasPendientes = []
    let fincasVisitadas = []
    let fincasRenovadasPendientes = []
    let fincasRenovadasVisitadas = []
    let hojasVisita = []
    let centrosTrabajo = []
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => {
      if(doc.data().nifTecnico !=="" && doc.data().nifTecnico !== undefined){
        promises.push(firestore.collection('tecnicos').doc(doc.data().nifTecnico).get().then(doc => {
          tecnico = doc.data()
        }))
      }else{tecnico = {nombre:"-", apellidos:""}}
      if(doc.data().nifConsultor !=="" && doc.data().nifConsultor !== undefined){
        promises.push(firestore.collection('consultores').doc(doc.data().nifConsultor).get().then(doc => {
          consultor= doc.data()
        }))
      }else{consultor = {nombre:"-", apellidos:""}}
      if(doc.data().nifComercial !=="" && doc.data().nifComercial !== undefined){
        promises.push(firestore.collection('comerciales').doc(doc.data().nifComercial).get().then(doc => {
          comercial= doc.data()
        }))
      }else{comercial = {nombre:"-", apellidos:""}}
      
      promises.push(firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).get().then(snapshot => {
        let data = []
        
        snapshot.docs.forEach(e => {
          //data.push(e.data())
          let finca = e.data()
          if(e.data().activo === true || e.data().activo === "true" ||e.data().activo === undefined){
            firestore.collection("hojasVisita").doc(e.data().nif).get().then( snapshot => {
              if(snapshot.exists){
                hojasVisita.push(snapshot.data().comunidad.nif)
                if(snapshot.data().centrosTrabajo !== undefined){
                  snapshot.data().centrosTrabajo.forEach(ct => {
                    centrosTrabajo.push(ct.nif)
                  })
                }
                let dias = ""
                let year = new Date(snapshot.data().fechaVisitaRenovacion?.seconds*1000).getFullYear()
                if(e.data().renovada && snapshot.data().fechaVisitaRenovacion !== undefined && year !== 1970){
                  //console.log(e.data().nif, this.daysDiff(new Date(snapshot.data().fechaVisitaRenovacion?.seconds*1000), new Date()), e.data().renovadaVisitada)
                  dias = this.daysDiff(new Date(snapshot.data().fechaVisitaRenovacion?.seconds*1000), new Date())
                }else{
                  //console.log(e.data().nif, this.daysDiff(new Date(snapshot.data().fechaVisita?.seconds*1000), new Date()), e.data().renovadaVisitada)
                  dias = this.daysDiff(new Date(snapshot.data().fechaVisita?.seconds*1000), new Date())
                }
                if(e.data().renovada && !e.data().renovadaVisitada && dias > 304){ //9 meses = 273 dias - 6 meses = 182 dias - 10 meses = 304 dias
                  finca.visita = 1  
                  fincasRenovadasPendientes.push(finca)
                }else if(e.data().renovadaVisitada){
                  finca.visita = 0
                  fincasRenovadasVisitadas.push(finca)
                }else{
                  finca.visita = 0
                  fincasVisitadas.push(finca)
                }
              }else{
                finca.visita = 1
                fincasPendientes.push(finca)
    
              }
            })
          }
        })
        
      }))
      setTimeout(()=>{
        Promise.all(promises).then(() => {
          if(this.state.tab === 0){
            this.setState({ fincas: fincasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 2){
            this.setState({ fincas: fincasRenovadasPendientes })
            this.setState({mensaje: "No hay fincas pendientes"})
          }else if(this.state.tab === 3){
            this.setState({ fincas: fincasRenovadasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }else{
            this.setState({ fincas: fincasVisitadas })
            this.setState({mensaje: "No hay fincas visitadas"})
          }         
          
          this.setState({ aaff: doc.data(), observaciones: doc.data().observacionesTecnico, fincasPendientes: fincasPendientes, fincasVisitadas: fincasVisitadas, fincasRenovadasVisitadas: fincasRenovadasVisitadas, fincasRenovadasPendientes: fincasRenovadasPendientes, isLoading: false })
          this.setState({ tecnico: tecnico, consultor: consultor, comercial: comercial, hojasVisita: hojasVisita, centrosTrabajo: centrosTrabajo })
        })
      },3000)
    }).catch(err => window.location.replace("/aaff"))
    setTimeout(() =>{
      this.ordenarCP();
    },3800)
  }

  handleChange = (event, newValue) => {
    if(newValue === 0){
      this.setState({ fincas: this.state.fincasPendientes })
      this.setState({mensaje: "No hay fincas pendientes"})
    }else if(newValue === 2){
      this.setState({ fincas: this.state.fincasRenovadasPendientes })
      this.setState({mensaje: "No hay fincas pendientes"})
    }else if(newValue === 3){
      this.setState({ fincas: this.state.fincasRenovadasVisitadas })
      this.setState({mensaje: "No hay fincas visitadas"})
    }else{
      this.setState({ fincas: this.state.fincasVisitadas })
      this.setState({mensaje: "No hay fincas visitadas"})
    }
    this.setState({tab: newValue});
    setTimeout(() =>{
      this.ordenarCP();
    },800)
  };
  ordenarCP = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      //console.log("a: "+a.codigoPostal+" "+"b "+b.codigoPostal)
      
      if (a.codigoPostal > b.codigoPostal) {
        return 1;
      }
      if (a.codigoPostal < b.codigoPostal) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
  }

  
  ordenarCPHeader = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (this.state.tipoOrdenCP === 'asc') {
        return a.codigoPostal - b.codigoPostal
      }else{
        return b.codigoPostal - a.codigoPostal
      }
    })
    this.setState(this.state.tipoOrdenCP === 'asc' ? {tipoOrdenCP:'desc'} : {tipoOrdenCP:'asc'})
  }

  ordenarFecha = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (this.state.tipoOrden === 'asc') {
        return a.fechaAlta - b.fechaAlta;
      } else {
        return b.fechaAlta - a.fechaAlta;
      }
    });
    this.setState(this.state.tipoOrden === 'asc' ? {tipoOrden:'desc'} : {tipoOrden:'asc'})
  };

  ordenarSinArchivos = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      //console.log("a: "+a.codigoPostal+" "+"b "+b.codigoPostal)
      if (a.documentos?.evaluacionRiesgos?.validado === true || a.documentos?.evaluacionRiesgos?.validado === "true") {
        return 1; 
      }
      if (b.documentos?.evaluacionRiesgos?.validado === true || b.documentos?.evaluacionRiesgos?.validado === "true") {
        return -1; 
      }

      if (a.documentos?.evaluacionRiesgos?.validado > b.documentos?.evaluacionRiesgos?.validado) {
        return 1;
      }
      if (a.documentos?.evaluacionRiesgos?.validado < b.documentos?.evaluacionRiesgos?.validado) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
                    
  }

  cambiarObservaciones = (evt) => {
    let value = evt.target.value
    this.setState({observaciones: value});
  }
  guardarObservaciones = () => {
    firestore.collection('aaff').doc(this.state.aaff.nif).update({
      observacionesTecnico: this.state.observaciones
    }).then(() => {
      toast.success("Observaciones guardadas")
    }).catch(err => {
      toast.error("Error al guardar")
      console.log(err)
    })

  }

  monthDiff = (dateFrom, dateTo) => {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) //mostrar la diferencia en meses
  }

  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }
  
  render() {
    if (this.state.fincas === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  ><span>Administrador / <b>{this.state.aaff.razonSocial}</b></span></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              INFORMACIÓN GENERAL
              {/* <Link to="/aaff"><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link> */}
            </div>
            <div className="gridSubhead editarSubhead">
              <div>DATOS EMPRESA</div>
            </div>
            <div className="datosGrid">
              <p><b>Nombre AAFF:</b> {this.state.aaff.razonSocial}</p>
              <p><b>NIF:</b> {this.state.aaff.nif}</p>
              <p><b>Teléfono:</b> {this.state.aaff.telefono !== undefined ? this.state.aaff.telefono : "-"}</p>
              <p><b>Persona de contacto:</b> {this.state.aaff.contacto !== undefined ? this.state.aaff.contacto : "-"}</p>
              <p><b>Municipio:</b> {this.state.aaff.municipio}</p>
              <p><b>Provincia:</b> {this.state.aaff.provincia}</p>
              <p><b>Email:</b> {this.state.aaff.email}</p>
              <p><b>Consultor:</b> {this.state.consultor?.nombre+ " "+this.state.consultor?.apellidos} </p>
              <p><b>Técnico:</b> {this.state.tecnico.nombre+ " "+this.state.tecnico.apellidos} </p>
              <p><b>Comercial:</b> {this.state.comercial?.nombre+ " "+this.state.comercial?.apellidos+ " - "+this.state.comercial?.email } </p>
              <p><b>Observaciones:</b> 
                <form  noValidate autoComplete="off" style={{display:'flex', alignItems:'end'}}>
                  <textarea style={{marginRight:'10px', border: '1px solid #ccc', width:'100%'}} id="observaciones" name="observaciones" label="observaciones" value={this.state.observaciones} onChange={this.cambiarObservaciones} multiline rows={6} columns={100}/>
                  <div >
                    <Button className="btn-Secondary" variant="contained" color="secondary" onClick={this.guardarObservaciones}>Guardar</Button>
                  </div>
                </form>
              </p>
            </div>
            <div className="gridSubhead">LISTADO COMUNIDADES</div>
            <div className="tableContainer">
              <Tabs
                value={this.state.tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChange}
              >
                <Tab label="No visitadas" />
                <Tab label="Visitadas" />
                <Tab label="Renovadas no Visitadas" />
                <Tab label="Renovadas Visitadas" />
              </Tabs>
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Comunidad</th>
                      <th>NIF</th>
                      <th onClick={() => this.ordenarFecha()} style={{ cursor:'pointer'}}>Fecha alta</th>
                      <th>Dirección</th>
                      <th onClick={() => this.ordenarCPHeader()} style={{ cursor:'pointer'}}>C.P.</th>
                      <th>Municipio</th>
                      <th>Activa</th>
                      <th>Servicios</th>
                      <th>Empleados</th>
                      <th style={{ textAlign: "center" }}>Identificación de riesgos y medidas preventivas</th>
                      <th style={{ textAlign: "center" }}>Plan de emergencia</th>
                      <th style={{ textAlign: "center" }}>Plan de prevención</th>
                      <th style={{ textAlign: "center" }}>Hoja de visita</th>
                      <th style={{ textAlign: "center" }}>No visitada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.isLoading ? <tr><td colSpan='15' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> : 
                     this.state.fincas.length===0 ? <tr><td colSpan='15' style={{textAlign:'center'}}>{this.state.mensaje}</td></tr> :
                    this.state.fincas.map((e, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.nif}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{new Date(e.fechaAlta.seconds * 1000).toLocaleDateString()}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.direccion}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.codigoPostal}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.municipio}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Sí" : "No"}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE"}</Link></td>
                            <td><Link to={"/comunidades/" + e.nif}>{e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No"}</Link></td>
                            {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ?  
                              <>
                                <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.validado ? <Link to={`/comunidades/${e.nif}/evaluacion-riesgos`} target={'_blank'}><FaCheck size={20} color="#7bc977" className="tableIcon" /></Link> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                                <td style={{ textAlign: "center" }}>{e.documentos.planEmergencia?.validado ? <Link to={`/comunidades/${e.nif}/PlanEmergencia`} target={'_blank'}><FaCheck size={20} color="#7bc977" className="tableIcon" /></Link> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                                <td style={{ textAlign: "center" }}>{e.documentos.prevencionRiesgos?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" onClick={() => abrirArchivo(e.documentos.prevencionRiesgos?.ruta)} /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                                <td style={{ textAlign: "center", width: 105 }}>
                                  {this.state.hojasVisita.includes(e.nif) ? 
                                    <Link to={`/comunidades/${e.nif}/hoja-visita`} target={'_blank'}><IoDocumentTextOutline size={30} className="tableIcon" style={{ opacity: e.documentos.hojaVisita?.validado ? 1 : 0.4 }} /></Link>
                                  :
                                    <IoDocumentTextOutline size={30} className="tableIcon" style={{ opacity: e.documentos.hojaVisita?.validado ? 1 : 0.4 }} onClick={() => abrirArchivo(e.documentos.hojaVisita?.ruta)} />
                                  }
                                  <Link to={"/comunidades/" + e.nif}><PiPencilLine size={30} className="tableIcon" style={{ marginLeft: 15 }} /></Link>
                                </td>
                                <td style={{ textAlign: "center", width: 105 }}>{e.visita === 1 ? <NoVisitado finca={e} nif={e.nif} tipo={'finca'} /> : null}</td>
                              </>
                            : 
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </> 
                            }
                          </tr>
                          {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? null : 
                            <tr>
                            <td colSpan="16">
                              <Collapse in={this.state.expandir}>
                                <table className="subTable">
                                  <tbody>
                                    {e.centrosTrabajo.map((c,j) => {
                                      return(
                                        <tr key={j}>
                                          <td colSpan={4} style={{ width:'175px'}}>{c.nombre}</td>
                                          <td style={{ width:'10%'}}></td>
                                          <td style={{ width:'10%'}}></td>
                                          <td style={{ width:'10%'}}></td>
                                          <td style={{ width:'8%'}}></td>
                                          <td style={{ textAlign: "center" }}>{c.documentos.evaluacionRiesgos?.validado ? 
                                            this.state.centrosTrabajo.includes(c.nif) ? 
                                              <Link to={`/comunidades/${e.nif}/${c.nif}/evaluacion-riesgos`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /> </Link>
                                            :
                                              <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(c.documentos.evaluacionRiesgos?.ruta)} /> 
                                          : 
                                            <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                          <td style={{ textAlign: "center" }}>{c.documentos.planEmergencia?.validado ? 
                                            this.state.centrosTrabajo.includes(c.nif) ? 
                                              <Link to={`/comunidades/${e.nif}/${c.nif}/PlanEmergencia`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /> </Link>
                                            :
                                              <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(c.documentos.planEmergencia?.ruta)} /> 
                                          : 
                                            <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                          <td style={{ textAlign: "center" }}>{c.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(c.documentos.prevencionRiesgos?.ruta)} /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                                          <td style={{ textAlign: "center", width: 105 }}>
                                            {this.state.centrosTrabajo.includes(c.nif) ? 
                                              <>
                                                <Link to={`/comunidades/${e.nif}/${c.nif}/hoja-visita`} target={'_blank'}><IoDocumentTextOutline size={30} className="tableIcon" style={{ opacity: e.documentos.hojaVisita?.validado ? 1 : 0.4 }} /></Link>
                                                <Link to={"/comunidades/" + e.nif + "/" +c.nif}><PiPencilLine size={30} className="tableIcon" style={{ marginLeft: 15 }} /></Link>
                                              </>
                                            :
                                              <>
                                                <IoDocumentTextOutline size={30} className="tableIcon" style={{ opacity: c.documentos.hojaVisita?.validado ? 1 : 0.4 }} onClick={() => abrirArchivo(c.documentos.hojaVisita?.ruta)} />
                                                <Link to={"/comunidades/" + e.nif + "/" +c.nif}><PiPencilLine size={30} className="tableIcon" style={{ marginLeft: 15 }} /></Link>
                                              </>
                                            }
                                          </td>
                                          <td style={{ textAlign: "center", width: 105 }}>{e.visita === 1 ? <NoVisitado finca={e} nif={c.nif} tipo={'centroTrabajo'} /> : null}</td>
                                        </tr>
                                      )
                                    })}

                                  </tbody>
                                </table>
                              </Collapse>
                            </td>

                          </tr>
                          }
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Comunidades);

