import React from "react";
import { Link } from "react-router-dom";
import { firestore, storage, auth } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { toast } from "react-toastify";
import orderArray from "../../../providers/orderArray";


class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      aaffs: [],
      tecnicos: {},
      consultores: {}
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    firestore.collection("fincas").where("nifProveedores", "array-contains", auth.currentUser.uid).orderBy("razonSocial").get().then(snapshot => {
      let aaffs = []
      let nifAAFF = []
      snapshot.docs.forEach(doc => {
        let finca = doc.data()
        nifAAFF.push(finca.nifAAFF)
      })
      nifAAFF = [...new Set(nifAAFF)]
      let promises = []
      nifAAFF.forEach(aaff => promises.push(firestore.collection("aaff").doc(aaff).get().then(docAAFF => aaffs.push(docAAFF.data()))))
      Promise.all(promises).then(() => {
        this.setState({ aaffs: orderArray(aaffs) })
      })
    })
  }
  descargar = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  ><span>Administradores de Fincas</span></div>
        <div className="pageContent tableContainer">
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Administrador</th>
                  <th>NIF</th>
                  <th>Teléfono</th>
                </tr>
              </thead>
              <tbody>
                {this.state.aaffs.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td><Link to={"/aaff/"+ e.nif +"/comunidades/"}>{e.razonSocial}</Link></td>
                      <td><Link to={"/aaff/"+ e.nif +"/comunidades/"}>{e.nif}</Link></td>
                      <td></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default AAFF;

