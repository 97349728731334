import React from "react";
import { firestore, storage, auth } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Minus from "../../../icons/minus.svg"
import Documento from "../../../icons/document.svg"
import Aviso from "../../../icons/email.svg"
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import { IoDocumentTextOutline } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { TiMinus } from "react-icons/ti";

class Proveedor extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedor: undefined,
      fincas: []
    }
  }
  componentDidMount() {
    firestore.collection("proveedores").doc(this.props.params.nif).get().then(snapshot => {
      let proveedor = snapshot.data();
      let fincas = [];
      firestore.collection("fincas").where("nifProveedores", "array-contains", this.props.params.nif).where("nifAAFF", "==", auth.currentUser.uid).get().then(res => {
        res.forEach(finca => fincas.push(finca.data()))
        this.setState({ fincas: fincas, proveedor: proveedor })
      })
    }).catch(error => {
      window.location.replace("/")
    })
  }
  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }

  }

  render() {
    if (!this.state.proveedor || this.state.fincas === []) return null //Loading data...
    return <>
      <div className="pageHeader"  >Proveedor / <b>{this.state.proveedor?.razonSocial}</b></div>
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS EMPRESA</div>
          <div className="datosGrid">
            <p><b>Nombre comunidad:</b> {this.state.proveedor?.razonSocial}</p>
            <p><b>Dirección:</b> {this.state.proveedor?.direccion}</p>
            <p><b>NIF:</b> {this.state.proveedor?.nif}</p>
            <p><b>Municipio:</b> {this.state.proveedor?.municipio}</p>
            <p><b>Persona de contacto:</b> {this.state.proveedor?.contacto.nombre}</p>
            <p><b>CP:</b> {this.state.proveedor?.codigoPostal}</p>
            <p><b>Email:</b> {this.state.proveedor?.contacto?.email}</p>
            <p><b>Provincia:</b> {this.state.proveedor?.provincia}</p>
            <p><b>Telefono:</b> {this.state.proveedor?.contacto?.telefono}</p>
            <p><b>Última reclamación:</b> {this.state.proveedor?.ultimaReclamacion !== undefined ? new Date(this.state.proveedor?.ultimaReclamacion?.seconds * 1000).toLocaleDateString() : "-"}</p>
          </div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                <th></th>
                <th></th>
                <th style={{ margin: 5 }}>Ver</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.state.proveedor.documentos.lopd?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.lopd?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Certificado LOPD <small style={{display:'block', margin:'5px 0'}}>-</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.lopd?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.seguro?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.seguro?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Seguro de responsabilidad civil <small style={{display:'block', margin:'5px 0'}}>{this.state.proveedor.documentos.seguro?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.seguro?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.seguro?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.justificante?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.justificante?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Justificante de pago de Servicio de Prevención Ajeno <small style={{display:'block', margin:'5px 0'}}>{this.state.proveedor.documentos.justificante?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.justificante?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.justificante?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.planificacionPreventiva?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.planificacionPreventiva?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Planificación preventiva <small style={{display:'block', margin:'5px 0'}}>-</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.planificacionPreventiva?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.evaluacionRiesgos?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.evaluacionRiesgos?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Evaluación de riesgos <small style={{display:'block', margin:'5px 0'}}>-</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.evaluacionRiesgos?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.certificadoSS?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.certificadoSS?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Certificado corriente de pago Seguridad Social <small style={{display:'block', margin:'5px 0'}}>{this.state.proveedor.documentos.certificadoSS?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.certificadoSS?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.vigilancia?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.vigilancia?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Vigilancia de la salud del trabajador <small style={{display:'block', margin:'5px 0'}}>{this.state.proveedor.documentos.vigilancia?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.vigilancia?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.vigilancia?.ruta)} /></td>
              </tr>
              <tr>
                <td>{this.state.proveedor.documentos.declaracionResponsable?.[auth.currentUser.uid]?.pendiente ? <TiMinus size={20} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.declaracionResponsable?.[auth.currentUser.uid]?.validado ? <FaCheck size={20} color="#7bc977" className="tableIcon" /> : <ImCross size={20} color="#FF5252" className="tableIcon" />}</td>
                <td style={{ width: '100%' }}>Declaración responsable <small style={{display:'block', margin:'5px 0'}}>-</small></td>
                <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.proveedor.documentos.declaracionResponsable?.[auth.currentUser.uid]?.ruta)} /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="gridCard">
          <div className="gridHead">FINCAS</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                <th></th>
                {/* <th>Aviso</th> */}
              </tr>
            </thead>
            <tbody>
              {this.state.fincas.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ width: '100%' }}><Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link></td>
                    {/* <td style={{ textAlign: 'center' }}><img src={Aviso} alt="Aviso" className="tableIcon" /></td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }
}

export default withRouter(Proveedor);
